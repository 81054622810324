import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IGamePiece } from '../../stores/domain';

interface ILooseElectronProps {
  data: IGamePiece;
  isDragged?: boolean;
  isShadow?: boolean;
}

export const LooseElectron: FC<ILooseElectronProps> = observer(
  ({ data, isDragged = false, isShadow = false }) => {
    return (
      <>
        {data.isSelected && !isDragged && (
          <circle
            cx="25"
            cy="25"
            r="7"
            stroke="#FCE300"
            strokeWidth="6"
            fill="#FCE300"
            strokeOpacity="0.4"
          />
        )}
        <line
          x1="30"
          x2="36"
          y1="17"
          y2="17"
          stroke={isShadow ? 'silver' : 'black'}
        />
        <circle
          cx="25"
          cy="25"
          r="2"
          fill={isShadow ? 'none' : 'black'}
          stroke={isShadow ? 'silver' : 'none'}
        />
      </>
    );
  }
);
