import React, { FC, ReactNode } from 'react';
import { Icon } from '../Icon';
import { stopPropagation } from '../../utils';

export interface IModalFrameProps {
  children: ReactNode;
  title: ReactNode;
  maxWidth?: number;
  onCloseClick(): void;
}

export const ModalFrame: FC<IModalFrameProps> = ({
  title,
  onCloseClick,
  children,
  maxWidth,
}) => {
  return (
    <div
      className="max-h-[calc(100dvh-20px)] max-w-screen bg-white shadow-outer overflow-hidden flex flex-col mx-auto enableTouch rounded-md"
      style={{
        maxWidth: maxWidth
          ? `min(${maxWidth}px, calc(100vw - 20px))`
          : 'calc(100vw - 20px)',
        width: maxWidth ? 'auto' : '430px',
      }}
    >
      <div className="text-lg font-bold flex bg-blue">
        <div className="flex-1 font-semibold text-white p-md">{title}</div>
        <button
          tabIndex={-1}
          onKeyDown={stopPropagation}
          type="button"
          onClick={onCloseClick}
          className="w-[50px]"
        >
          <Icon id="close" className="text-xl text-white" />
        </button>
      </div>
      {children}
    </div>
  );
};
