import { reaction, runInAction } from 'mobx';

import { ICAPI } from '../capi-core/types';

type Mapper<A, B> = (input: A) => B;

function identity<T>(input: any) {
  return input as unknown as T;
}

export class CapiBoundStore<M> {
  protected capi: ICAPI<M>;

  constructor(capi: ICAPI<M>) {
    this.capi = capi;
  }

  protected bindToCapi<V extends keyof this, C extends keyof M>(
    localFieldName: V,
    capiVariableName: C,
    mapFromCapi: Mapper<M[C], this[V]> = identity,
    mapToCapi: Mapper<this[V], M[C]> = identity
  ) {
    reaction(
      () => this[localFieldName],
      (newValue: this[V]) =>
        this.capi.set(capiVariableName, mapToCapi(newValue))
    );

    this.capi.onChange(capiVariableName, (newValue) => {
      runInAction(() => (this[localFieldName] = mapFromCapi(newValue)));
    });

    runInAction(
      () =>
        (this[localFieldName] = mapFromCapi(this.capi.get(capiVariableName)))
    );
  }

  protected booleanTrigger<C extends keyof M>(
    capiVariableName: C,
    reaction: () => void
  ) {
    this.capi.onChange(capiVariableName, (newValue) => {
      if (!newValue) return;

      reaction();
      this.capi.set(capiVariableName, false as unknown as M[C]);
    });
  }

  protected onCapi<C extends keyof M>(
    capiVariableName: C,
    reaction: (newValue: M[C]) => void
  ) {
    this.capi.onChange(capiVariableName, reaction);
    reaction(this.capi.get(capiVariableName));
  }

  protected synchronizeToCapi<V extends keyof this, C extends keyof M>(
    localFieldName: V,
    capiVariableName: C,
    mapToCapi: Mapper<this[V], M[C]> = identity
  ) {
    reaction(
      () => this[localFieldName],
      (newValue: this[V]) =>
        this.capi.set(capiVariableName, mapToCapi(newValue))
    );
  }

  protected synchronizeFromCapi<V extends keyof this, C extends keyof M>(
    localFieldName: V,
    capiVariableName: C,
    mapFromCapi: Mapper<M[C], this[V]> = identity
  ) {
    this.capi.onChange(capiVariableName, (newValue) => {
      runInAction(() => {
        this[localFieldName] = mapFromCapi(newValue);
      });
    });

    runInAction(() => {
      this[localFieldName] = mapFromCapi(this.capi.get(capiVariableName));
    });
  }
}
