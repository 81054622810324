import React, { FC } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { stopSpecialPropagation } from '../../utils';
import { IconId } from '../../icon-definitions';
import { Icon } from '../Icon';

export interface IIconButtonProps {
  className?: string;
  iconId: IconId;
  disabled?: boolean;

  id?: string;
  label?: string;

  onClick(): void;
}

export const IconButton: FC<IIconButtonProps> = ({
  className,
  iconId,
  onClick,
  disabled = false,
  id,
  label,
}) => (
  <>
    {id && label && (
      <ReactTooltip
        id={id}
        effect="solid"
        type="dark"
        className="whitespace-nowrap"
      >
        {label}
      </ReactTooltip>
    )}
    <button
      data-tip
      data-for={id}
      className={classNames(
        'border border-gray-250 bg-gray-100 rounded-sm w-[36px] h-[36px] flex items-center justify-center text-gray-600 text-xl disabled:opacity-50 focus:outline-none focus:ring focus:ring-keyboardCue',
        className
      )}
      disabled={disabled}
      onKeyDown={stopSpecialPropagation}
      onClick={onClick}
    >
      <Icon id={iconId} />
    </button>
  </>
);
