import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useStores } from '../../providers/store/use-stores';
import { IDimensions } from '../../stores/domain';

export function useBoardSize(onResize: (newDimensions: IDimensions) => void) {
  const {
    appStore: { zoom },
  } = useStores();
  const boardRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 100 * zoom,
    height: 100 * zoom,
  });

  useLayoutEffect(() => {
    const rect = boardRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const width = rect.width * zoom;
    const height = rect.height * zoom;

    setDimensions({ width, height });
    onResize({ width, height });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const {
        contentRect: { width, height },
      } = entries[0];
      setDimensions({ width, height });
      onResize({ width, height });
    });

    if (boardRef.current) {
      resizeObserver.current.observe(boardRef.current);
    }

    return () => {
      resizeObserver.current?.disconnect();
    };
  }, []);

  return {
    boardRef,
    dimensions,
  };
}
