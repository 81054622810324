import React, { FC } from 'react';

export interface IKey {
  children: string;
}

export const Key: FC<IKey> = ({ children }) => (
  <div className="inline-block bg-skyblue-light py-0.5 px-1.5 border border-skyblue min-w-[21px] text-center uppercase leading-[1] text-sm rounded-md">
    {children}
  </div>
);
