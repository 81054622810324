import React, { FC, useEffect } from 'react';
import { IGenericModal, useTabProps } from 'asu-sim-toolkit';

import { ModalFrame } from './ModalFrame';
import { ModalButton } from './ModalButton';

export interface IInfoModal extends IGenericModal<void> {
  title: string;
  message: string;
  confirmLabel?: string;
}

export const InfoModal: FC<IInfoModal> = ({
  setOverlayClickHandler,
  onResolve,
  title,
  message,
  confirmLabel = 'Ok',
}) => {
  setOverlayClickHandler(() => onResolve());

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onResolve();
      }
    };
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [onResolve]);

  const getTabProps = useTabProps();

  return (
    <ModalFrame title={title} onCloseClick={() => onResolve()}>
      <div className="overflow-y-auto p-md">{message}</div>
      <div className="flex justify-center px-md pb-md">
        <ModalButton
          primary
          autoFocus
          onClick={() => onResolve()}
          {...getTabProps()}
        >
          {confirmLabel}
        </ModalButton>
      </div>
    </ModalFrame>
  );
};
