import React, { FC } from 'react';
import classNames from 'classnames';

import { LR } from '../../stores/domain';
import { ReactionFormType } from '../ReactionForm/types';
import { KeyboardHint } from '../Keyboard/KeyboardHint';
import { useStores } from '../../providers/store/use-stores';
import { ControlScheme } from '../../stores/types';
import { observer } from 'mobx-react-lite';

export const ObjectiveType: FC = observer(() => {
  const {
    simulationStore: {
      leftObjectiveText,
      rightObjectiveText,
      activeTab,
      leftObjectiveVisible,
      rightObjectiveVisible,
      setActiveTab,
    },
    inputStore: { activeControlScheme },
  } = useStores();

  const type =
    leftObjectiveVisible && rightObjectiveVisible
      ? ReactionFormType.reaction
      : ReactionFormType.objective;

  const applyButtonClasses = (tab: LR) =>
    classNames(
      'w-full h-[calc(100%-5px)] rounded-t-lg mx-sm focus:outline-none focus:ring focus:ring-keyboardCue relative',
      tab === activeTab
        ? 'bg-blue border-b border-blue-accent'
        : 'border-b border-transparent'
    );

  return type === ReactionFormType.objective ? (
    <div className="basis-11 w-full text-lg flex items-center justify-center border-b border-blue-lighter select-none">
      <span>{leftObjectiveText || rightObjectiveText}</span>
    </div>
  ) : (
    <div className="basis-11 w-full text-lg flex items-end justify-center bg-blue-darker select-none">
      <button
        disabled={activeTab === LR.left}
        onClick={() => setActiveTab(LR.left)}
        className={applyButtonClasses(LR.left)}
      >
        {leftObjectiveText}
        {activeControlScheme === ControlScheme.keyboard &&
          activeTab !== LR.left && (
            <KeyboardHint className="absolute top-xs left-xs">L</KeyboardHint>
          )}
      </button>
      <button
        disabled={activeTab === LR.right}
        onClick={() => setActiveTab(LR.right)}
        className={applyButtonClasses(LR.right)}
      >
        {rightObjectiveText}
        {activeControlScheme === ControlScheme.keyboard &&
          activeTab !== LR.right && (
            <KeyboardHint className="absolute top-xs right-xs">R</KeyboardHint>
          )}
      </button>
    </div>
  );
});
