import { IGamePiece } from '../stores/domain';

export const sum = (a: number[]) => a.reduce((acc, n) => acc + n, 0);

export const calculateFreeElectrons = (gamePiece: IGamePiece) =>
  gamePiece.atom.valenceElectrons +
  gamePiece.charge -
  (gamePiece.bonds ? sum(gamePiece.bonds) : 0);

export const calculateAllElectrons = (gamePiece: IGamePiece) =>
  gamePiece.atom.valenceElectrons +
  gamePiece.charge +
  (gamePiece.bonds ? sum(gamePiece.bonds) : 0);
