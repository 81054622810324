import React, { FC, PointerEventHandler } from 'react';

import { IBoardPiece } from '../../stores/domain';
import { BOARD_PIECE_SIZE } from './consts';
import { getElementOffset } from '../../utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { ControlScheme } from '../../stores/types';

export interface IBoardPieceProps {
  boardpiece: IBoardPiece;

  onClick(boardpiece: IBoardPiece): void;
  onDrag(boardpiece: IBoardPiece): void;
  onDrop(boardpiece: IBoardPiece): void;
}

export const BoardPiece: FC<IBoardPieceProps> = observer(
  ({ boardpiece, onClick, onDrag, onDrop }) => {
    const {
      simulationStore: { draggedGamePiece },
      bankStore: { selectedBankPiece },
      inputStore: { moveCursorTo, activeControlScheme },
    } = useStores();
    const [x, y] = getElementOffset(boardpiece);

    const handlePointerDown: PointerEventHandler<SVGElement> = (event) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      onDrag(boardpiece);
    };

    const cursorPointer =
      boardpiece.piece && !draggedGamePiece ? 'cursor-pointer' : undefined;

    return (
      <>
        <rect
          data-occupied={!!boardpiece.piece}
          style={{ transform: `translate(${x}px, ${y}px)` }}
          className={cursorPointer}
          stroke="#dadada"
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
          shapeRendering="crispEdges"
          fill={
            boardpiece.isHighlighted ? 'url(#diagonalHatch)' : 'transparent'
          }
          width={BOARD_PIECE_SIZE}
          height={BOARD_PIECE_SIZE}
          onClick={() => onClick(boardpiece)}
          onPointerDown={handlePointerDown}
          onPointerUp={() => onDrop(boardpiece)}
          onPointerEnter={() =>
            activeControlScheme === ControlScheme.mouse &&
            moveCursorTo(boardpiece.coords)
          }
          cursor={
            draggedGamePiece
              ? 'none'
              : selectedBankPiece
              ? 'crosshair'
              : 'default'
          }
        />
      </>
    );
  }
);
