import React, { FC, ReactElement } from 'react';
import { useStores } from '../../providers/store/use-stores';
import { Coords, IBoardPiece } from '../../stores/domain';
import { BoardPiece } from './BoardPiece';

interface IBoardPieces {
  width: number;
  height: number;

  onBoardPieceClick(coords: Coords): void;
  onBoardPieceDrag(coords: Coords): void;
  onBoardPieceDrop(coords: Coords): void;
}

export const BoardPieces: FC<IBoardPieces> = ({
  width,
  height,
  onBoardPieceClick,
  onBoardPieceDrag,
  onBoardPieceDrop,
}) => {
  const {
    simulationStore: { board },
  } = useStores();
  const result: ReactElement[] = [];

  const handleBoardPieceClick = (boardpiece: IBoardPiece) => {
    onBoardPieceClick(boardpiece.coords);
  };

  const handleBoardPieceDrag = (boardpiece: IBoardPiece) => {
    onBoardPieceDrag(boardpiece.coords);
  };

  const handleBoardPieceDrop = (boardpiece: IBoardPiece) => {
    onBoardPieceDrop(boardpiece.coords);
  };

  for (let y = 0; y <= height - 1; y++) {
    for (let x = 0; x <= width - 1; x++) {
      result.push(
        <BoardPiece
          key={`${x}/${y}`}
          boardpiece={board.board[y][x]}
          onClick={handleBoardPieceClick}
          onDrag={handleBoardPieceDrag}
          onDrop={handleBoardPieceDrop}
        />
      );
    }
  }

  return <>{result}</>;
};
