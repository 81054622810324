import React, { FC } from 'react';

import { ReactionInput } from './ReactionInput';
import { ReactionInputType } from './types';
import { observer } from 'mobx-react-lite';

export interface IMolecule {
  template: string;
  slotOffset: number;
  slotValues: number[];
  isDisabled?: boolean;

  onChange(slotIndex: number, newValue: string): void;
}

export const Molecule: FC<IMolecule> = observer(
  ({ template, slotValues, slotOffset, onChange, isDisabled }) => {
    const tokens = Array.from(template);

    let localSlotIndex = -1;
    return (
      <>
        {tokens?.map((token, i) => {
          const key = 'token' + token + i;

          if (token === '_' && i === 0) {
            localSlotIndex++;
            const slotIndex = localSlotIndex + slotOffset;

            return (
              <ReactionInput
                key={key}
                type={ReactionInputType.coefficient}
                isDisabled={isDisabled}
                value={String(slotValues[slotIndex] || '')}
                onChange={(value) => onChange(slotIndex, value)}
              />
            );
          }

          if (token === '_' && i !== 0) {
            localSlotIndex++;
            const slotIndex = localSlotIndex + slotOffset;

            return (
              <ReactionInput
                key={key}
                type={ReactionInputType.subscript}
                isDisabled={isDisabled}
                value={String(slotValues[slotIndex] || '')}
                onChange={(value) => onChange(slotIndex, value)}
              />
            );
          }

          if (/[0-9]+/.test(token) && i !== 0) {
            return (
              <sub className="translate-y-0.5" key={key}>
                {token}
              </sub>
            );
          }

          if (/[+-]/.test(token) && i !== 0) {
            return <sup key={key}>{token}</sup>;
          }

          return <span key={key}>{token}</span>;
        })}
      </>
    );
  }
);
