import { Atoms, Bonds, Coords, IBoardPiece } from '../stores/domain';
import { getShortBoardPieces } from './get-short-board-pieces';

export const solutionEncoder = (boardPieces: IBoardPiece[]) => {
  return getShortBoardPieces(boardPieces)
    .map((boardPiece) => {
      const { atom, charge, bonds, coords } = boardPiece;
      const bondsString = bonds?.map((b) => String(b)).join('');

      return `${atom?.symbol}|${charge}|${bondsString}|${coords}`;
    })
    .join(';');
};

export const solutionDecoder: (value: string, atoms: Atoms) => IBoardPiece[] = (
  value: string,
  atoms: Atoms
) => {
  // TODO: Better error handling.
  //       This function should throw (or return valid but incomplete data)
  //       when given malformed input.
  if (value === '') return [];

  const result = [];
  const splitItems = value.split(';');

  for (const item of splitItems) {
    const itemParams = item.split('|');
    const atom = atoms[itemParams[0]];
    const charge = parseInt(itemParams[1]);
    const bonds = itemParams[2].split('').map(Number) as Bonds;
    const coords = itemParams[3].split(',').map(Number) as Coords;

    const formattedItem: IBoardPiece = {
      coords: coords as Coords,
      piece: {
        atom,
        isSelected: false,
        isLocked: false,
        bonds,
        charge,
        selectedBondIndex: null,
      },
      isHighlighted: false,
    };

    result.push(formattedItem);
  }

  return result;
};
