import React, { FC } from 'react';

interface IIonSign {
  x: number;
  y: number;
  charge: number;
  isShadow: boolean;
}

export const IonSign: FC<IIonSign> = ({ x, y, charge, isShadow }) => (
  <>
    <line
      x1={x - 3}
      x2={x + 3}
      y1={y}
      y2={y}
      stroke={isShadow ? 'silver' : 'black'}
    />
    {charge < 0 && (
      <line
        x1={x}
        x2={x}
        y1={y + 3}
        y2={y - 3}
        stroke={isShadow ? 'silver' : 'black'}
      />
    )}
  </>
);
