import React, { FC, useEffect, useState } from 'react';
import { IGenericModal, useTabProps } from 'asu-sim-toolkit';

import { ModalFrame } from './ModalFrame';
import { ModalButton } from './ModalButton';
import { useStores } from '../../providers/store/use-stores';
import { strings } from '../../strings';

const {
  successNotification,
  modalTitle,
  modalButton,
  modalButtonNoPermission,
} = strings.dataExport;

export interface IExportModal extends IGenericModal<void> {
  exportedString: string;
}

async function getClipboardPermission() {
  const permissions = await navigator.permissions.query({
    name: 'clipboard-write' as PermissionName,
  });

  return permissions.state !== 'denied';
}

export const ExportModal: FC<IExportModal> = ({
  setOverlayClickHandler,
  onResolve,
  exportedString,
}) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const { notificationStore } = useStores();
  setOverlayClickHandler(() => onResolve());

  useEffect(() => {
    getClipboardPermission().then(setHasPermission);
  }, []);

  const handleClose = async () => {
    if (hasPermission) {
      await navigator.clipboard.writeText(exportedString);
      notificationStore.addNotification(successNotification);
    }

    onResolve();
  };

  const getTabProps = useTabProps();

  return (
    <ModalFrame title={modalTitle} onCloseClick={handleClose}>
      <div className="flex flex-col items-center gap-md p-md">
        <textarea
          className="h-max-[200px] w-full border border-gray-200 rounded-md p-sm"
          defaultValue={exportedString}
          disabled
        />
        <ModalButton primary autoFocus onClick={handleClose} {...getTabProps()}>
          {hasPermission === null
            ? '...'
            : hasPermission
            ? modalButton
            : modalButtonNoPermission}
        </ModalButton>
      </div>
    </ModalFrame>
  );
};
