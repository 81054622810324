import { observer } from 'mobx-react-lite';
import React, { FC, ReactElement } from 'react';
import { Coords, IBoardPiece } from '../../stores/domain';
import { getElementOffset } from '../../utils';
import { BondGroup } from './BondGroup';

interface IBonds {
  boardPieces: IBoardPiece[];
}

const renderBonds = (
  [top, right, bottom, left]: [
    top: number,
    right: number,
    bottom: number,
    left: number
  ] = [0, 0, 0, 0],
  coords: Coords
) => {
  const result: ReactElement[] = [];
  const [x, y] = coords;

  if (top) result.push(<BondGroup key="top" count={top} positionIndex={0} />);
  if (right)
    result.push(<BondGroup key="right" count={right} positionIndex={1} />);
  if (bottom)
    result.push(<BondGroup key="bottom" count={bottom} positionIndex={2} />);
  if (left)
    result.push(<BondGroup key="left" count={left} positionIndex={3} />);

  return (
    <g key={`${x}/${y}`} style={{ transform: `translate(${x}px, ${y}px)` }}>
      {result}
    </g>
  );
};

export const Bonds: FC<IBonds> = observer(({ boardPieces }) => {
  const bonds = [];

  for (let i = 0; i <= boardPieces.length - 1; i++) {
    const pieceBonds = boardPieces[i].piece?.bonds;

    if (pieceBonds) {
      const coords = getElementOffset(boardPieces[i]);
      bonds.push(renderBonds(pieceBonds, coords));
    }
  }

  return <>{bonds}</>;
});
