import classNames from 'classnames';
import React, { FC, KeyboardEventHandler, ReactNode, RefObject } from 'react';

export interface IModalButtonProps {
  children: ReactNode;
  autoFocus?: boolean;
  className?: string;
  primary?: boolean;
  disabled?: boolean;
  inputRef?: RefObject<HTMLButtonElement>;

  onClick(): void;
  onKeyDown?: KeyboardEventHandler;
}

export const ModalButton: FC<IModalButtonProps> = ({
  children,
  onClick,
  autoFocus = false,
  className,
  primary,
  inputRef,
  onKeyDown,
  disabled = false,
}) => (
  <button
    autoFocus={autoFocus}
    disabled={disabled}
    className={classNames(
      'focus:outline-none focus:ring focus:ring-keyboardCue flex items-center justify-center border-2 border-blue-neutral min-h-[44px] min-w-[140px] px-md py-sm truncate rounded-md font-bold text-md',
      primary ? 'bg-blue-neutral text-white' : 'bg-white text-blue-neutral',
      className
    )}
    type="button"
    onClick={onClick}
    ref={inputRef}
    onKeyDown={onKeyDown}
  >
    {children}
  </button>
);
