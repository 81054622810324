import { Atoms } from '../stores/domain';
import { solutionComparator } from './solution-comparator';

/**
 * Compares two arrays of encoded molecules
 */
export const compareMoleculeSets = (
  inputValues: string[],
  correctValues: string[],
  atoms: Atoms
) => {
  const areMoleculesCorrect = correctValues.map((c) => {
    const index = inputValues.findIndex((i) => solutionComparator(i, c, atoms));

    if (index > -1) {
      inputValues.splice(index, 1);
      return true;
    }

    return false;
  });

  return { areMoleculesCorrect, extraneousMolecules: !!inputValues.length };
};
