const reloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue =
    'Your work will not be saved. Are you sure you want to leave?';
};

export const setReloadHandler = (shouldPreventReload: boolean) => {
  shouldPreventReload
    ? window.addEventListener('beforeunload', reloadHandler)
    : window.removeEventListener('beforeunload', reloadHandler);
};
