import {
  PointerEvent,
  TouchEvent,
  PointerEventHandler,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BOARD_PIECE_SIZE,
  OVERSCROLL_AMOUNT,
  PAN_DURATION_THRESHOLD,
} from './consts';
import { Coords, IDimensions } from '../../stores/domain';
import { clamp, isTouchDevice } from '../../utils';
import { useStores } from '../../providers/store/use-stores';

/**
 * Panning and centering logic
 * @param boardSize - board size measured in pieces
 */
export function useBoardPanning(boardSize: IDimensions) {
  const {
    appStore: { zoom },
  } = useStores();
  const isPointerDown = useRef<boolean>(false);
  const panDuration = useRef<number>(0);
  const viewportDimensions = useRef<IDimensions>({
    width: 100,
    height: 100,
  });
  const boardDimensions: IDimensions = {
    width: boardSize.width * BOARD_PIECE_SIZE,
    height: boardSize.height * BOARD_PIECE_SIZE,
  };

  const [offsets, setOffsets] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [previousTouch, setPreviousTouch] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const isTouchScreen = isTouchDevice();

  // TODO: Memoize these two based on board and viewport dimensions
  const maxX =
    boardDimensions.width -
    viewportDimensions.current.width +
    OVERSCROLL_AMOUNT;

  const maxY =
    boardDimensions.height -
    viewportDimensions.current.height +
    OVERSCROLL_AMOUNT;

  const getNewOffset = (movementX: number, movementY: number) => {
    const newX =
      boardDimensions.width > viewportDimensions.current.width
        ? clamp(offsets.x - movementX * zoom, -OVERSCROLL_AMOUNT, maxX)
        : offsets.x;

    const newY =
      boardDimensions.height > viewportDimensions.current.height
        ? clamp(offsets.y - movementY * zoom, -OVERSCROLL_AMOUNT, maxY)
        : offsets.y;

    return { x: newX, y: newY };
  };

  const handlePointerDown = (
    event: PointerEvent<SVGSVGElement> | TouchEvent<SVGSVGElement>
  ) => {
    const target = event.target as SVGSVGElement;
    if (target.dataset?.occupied === 'true') return;

    panDuration.current = 0;
    isPointerDown.current = true;
  };

  const handlePointerUp = () => {
    isPointerDown.current = false;
    isTouchScreen && setPreviousTouch(null);
  };

  const handlePointerMove: PointerEventHandler = (ev) => {
    if (!isPointerDown.current) {
      panDuration.current = 0;

      return;
    }

    panDuration.current += 1;

    setOffsets(getNewOffset(ev.movementX, ev.movementY));
  };

  const handleTouchMove: TouchEventHandler = (ev) => {
    if (!isPointerDown.current) return;

    panDuration.current += 1;

    if (previousTouch) {
      setOffsets(
        getNewOffset(
          ev.touches[0].pageX - previousTouch.x,
          ev.touches[0].pageY - previousTouch.y
        )
      );
    }

    setPreviousTouch({ x: ev.touches[0].pageX, y: ev.touches[0].pageY });
  };

  const center = () => {
    setOffsets({
      x: (-1 * (viewportDimensions.current.width - boardDimensions.width)) / 2,
      y:
        (-1 * (viewportDimensions.current.height - boardDimensions.height)) / 2,
    });
  };

  const setViewport = (newDimensions: IDimensions) => {
    viewportDimensions.current = newDimensions;
  };

  const scrollToCursor = ([cursorX, cursorY]: Coords) => {
    const pixelCursorX = cursorX * BOARD_PIECE_SIZE;
    const pixelCursorY = cursorY * BOARD_PIECE_SIZE;

    const newOffsetX = clamp(
      offsets.x,
      pixelCursorX - viewportDimensions.current.width + BOARD_PIECE_SIZE * 1.5,
      pixelCursorX - BOARD_PIECE_SIZE / 2
    );

    const newOffsetY = clamp(
      offsets.y,
      pixelCursorY - viewportDimensions.current.height + BOARD_PIECE_SIZE * 1.5,
      pixelCursorY - BOARD_PIECE_SIZE / 2
    );

    if (newOffsetX !== offsets.x || newOffsetY !== offsets.y) {
      setOffsets({ x: newOffsetX, y: newOffsetY });
    }
  };

  useEffect(() => {
    center();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSize.width, boardSize.height]);

  return {
    center,
    setViewport,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleTouchMove,
    offsets,
    scrollToCursor,

    getPanningState: () => panDuration.current >= PAN_DURATION_THRESHOLD,
  };
}
