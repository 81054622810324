import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Molecule } from './Molecule';

interface IReactionFormProps {
  template: string; // e.g. '_H_SO_' or 'N2 + _H20'
  slotValues: number[]; // values for "_"s
  className?: string;
  isDisabled?: boolean;

  onChange(slotIndex: number, value: string): void;
}

export const ReactionForm: FC<IReactionFormProps> = observer(
  ({ template, slotValues, onChange, className, isDisabled }) => {
    /**
     * Creates array of one or more segments
     *
     * example: ['_H2O', ' + ', 'H-_SO4']
     */
    const segments = template.match(/[\w+-]+|(\s\+\s)/gi) || [];

    const renderReaction = () => {
      let slotOffset = 0;

      return segments.map((segment, i) => {
        const key = 'segment_' + segment + '_' + i;

        if (segment === ' + ') {
          return (
            <span key={key} className="inline-block mx-3">
              +
            </span>
          );
        }

        const lastSlotOffset = slotOffset;
        const slotCount = segment
          .split('')
          .reduce((acc, t) => (t === '_' ? acc + 1 : acc), 0);
        slotOffset += slotCount;

        return (
          <Molecule
            key={key}
            template={segment}
            slotOffset={lastSlotOffset}
            slotValues={slotValues}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );
      });
    };

    return (
      <div
        className={classNames(
          'font-semibold text-xl',
          isDisabled && 'opacity-25',
          className
        )}
      >
        {renderReaction()}
      </div>
    );
  }
);
