import React, { FC } from 'react';
import classNames from 'classnames';
import { IconId } from '../../icon-definitions';
import { Icon } from '../Icon';

const chargeButtonClasses =
  'w-[32px] h-[32px] text-center rounded-[50%] text-black flex items-center justify-center';

const chargeButtonContainerClasses =
  'flex-1 bg-platinum-light p-[2px] flex align-center justify-center first:rounded-l-[50%] last:rounded-r-[50%]';

interface IChargeSelectorItemProps {
  currentCharge: number;
  charge: number;
  onClick: (newCharge: number) => void;
}

const iconByCharge: Record<number, IconId> = {
  '-1': 'cation',
  '0': 'neutral',
  '1': 'anion',
};

const ChargeSelectorItem: FC<IChargeSelectorItemProps> = ({
  currentCharge,
  charge,
  onClick,
}) => (
  <div className={chargeButtonContainerClasses}>
    <button
      className={classNames(
        chargeButtonClasses,
        currentCharge === charge && 'bg-blue-neutral'
      )}
      onClick={() => onClick(charge)}
    >
      <Icon
        id={iconByCharge[charge]}
        className={classNames(
          'text-lg stroke-[1.5px]',
          currentCharge === charge && 'text-white stroke-[3px]'
        )}
      />
    </button>
  </div>
);

interface IChargeSelectorProps {
  charge: number;
  onChange: (newCharge: number) => void;
}

export const ChargeSelector: FC<IChargeSelectorProps> = ({
  charge,
  onChange,
}) => {
  return (
    <div className="flex m-1.5">
      <ChargeSelectorItem
        charge={-1}
        currentCharge={charge}
        onClick={onChange}
      />
      <ChargeSelectorItem
        charge={0}
        currentCharge={charge}
        onClick={onChange}
      />
      <ChargeSelectorItem
        charge={1}
        currentCharge={charge}
        onClick={onChange}
      />
    </div>
  );
};
