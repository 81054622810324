import { IUpdateEntry } from './stores/recap/types';

export const versionHistory: IUpdateEntry[] = [
  {
    date: new Date('2022-09-20').getTime(),
    title: '1.1.0 - Up and Atom',
    description:
      'Adding, moving and removing atoms, creating and deleting bonds, solution verification logic. The works.',
    changelog: {
      'Core functionality': [
        {
          description: 'Atoms can now be added to and removed from the board.',
          type: 'feature',
        },
        {
          description:
            'By selecting an atom on the board and then clicking a valid adjacent atom, the user can create bonds.',
          type: 'feature',
        },
        {
          description:
            'To delete a bond, select any of the atoms involved and click the "unlink" icon in the toolbar.',
          type: 'feature',
        },
        {
          description:
            'You can move atoms by selecting them and then clicking any unoccupied square.',
          type: 'feature',
        },
        {
          description:
            'New CAPI variables under Sim.Result: ExpectedMolecules (encoded solution exported from the sim, beforehand), CorrectMolecules (an array of true/false values telling you which expected molecules check out), Correct (tells you all molecules check out and there are no other things on the board) and ExtraneousMolecules (whether there is anything on the board that does not match any of the expected molecules).',
          type: 'feature',
        },
      ],
    },
  },
  {
    date: new Date('2022-10-06').getTime(),
    title: '1.2.0 - The "Update" Update',
    description:
      'Minor bugfixes and a slick Update Recap pop-up. Yes, you are looking at it, right now.',
    changelog: {
      'Core functionality': [
        {
          description:
            'Fixed undo/redo edge-case where change history would skip one entry after a redo',
          type: 'fix',
        },
        {
          description:
            'Information about current and past updates is now displayed as a pop-up (unless already seen) and available via an icon in Debug Panel',
          type: 'feature',
        },
      ],
    },
  },
  {
    date: new Date('2022-10-07').getTime(),
    title: '1.3.0 - The Heisenberg Update',
    description:
      'Better electron rendering (both position AND momentum), decreased tech debt and 100% more boards.',
    changelog: {
      'Core functionality': [
        {
          description:
            'Separate reactant/product boards. Reactant board state gets saved when switching to product board and restored when switching back. Product board state gets saved when switching back to reactant board, but any changes to reactant board will reset it.',
          type: 'feature',
        },
      ],
      Visuals: [
        {
          description:
            'Improved rendering of electrons. Now, the logic behind it prioritises four sides, but can be adjusted to prioritise pairing.',
          type: 'feature',
        },
        {
          description:
            'Bonds are now more visible and generally more aligned with the designs.',
          type: 'feature',
        },
        {
          description:
            'The cursor no longer disappears over the toolbar when a bank item is selected.',
          type: 'fix',
        },
      ],
      'Code quality': [
        {
          description: 'Better test coverage',
          type: 'feature',
        },
        {
          description: 'Improved bank data-store code',
          type: 'feature',
        },
      ],
    },
  },
  {
    date: new Date('2022-10-20').getTime(),
    title: '1.6.0 - Update IV: Electron Boogaloo',
    description:
      'Loose electrons, configurable atoms, drag, drop and keyboard controls.',
    changelog: {
      'Core functionality': [
        {
          description: `It is now possible to detach an electron from a viable atom, move it around and - eventually - attach it to an atom.`,
          type: 'feature',
        },
        {
          description: 'Atoms are now configurable via CAPI.',
          type: 'feature',
        },
        {
          description:
            'Board pieces on the last row and/or last column no longer break the sim.',
          type: 'fix',
        },
      ],
      'UI and visuals': [
        {
          description: 'Board pieces are now moveable via drag-and-drop.',
          type: 'feature',
        },
        {
          description: `Full keyboard control scheme with its own board cursor and UI hints:
          - Arrow keys (or [W/S/A/D]) move the cursor.
          - [Space]/[return] are the equivalent of pointer click.
          - [Del] deletes bonds from atom under cursor. If there are no bonds to delete - it deletes the atom.
          - [P/R] switch between product and reactant boards.
          - Number keys select bank pieces.
          - [+] detaches an electron if viable atom is under cursor.
    
          Keyboard hints appear after using any of these keys and disappear (to reduce screen clutter) on mouse move. For now, they're all <i style="color:deepskyblue">deepskyblue</i>, but that's easily adjustable. 
          `,
          type: 'feature',
        },
        {
          description: 'Focus outline is now more visible.',
          type: 'feature',
        },
        {
          description: 'Clicking a selected bank piece deselects it.',
          type: 'feature',
        },
        {
          description: 'Bank will now scroll vertically if needed',
          type: 'feature',
        },
        {
          description:
            'Reactant/product formulas are only editable when their respective board is active.',
          type: 'feature',
        },
        {
          description: 'Visual cues when moving/creating atoms',
          type: 'feature',
        },
        {
          description: 'Simplified "new atom" cursor',
          type: 'feature',
        },
        {
          description: 'Box shadows for Bank and Toolbar are now fixed',
          type: 'fix',
        },
        {
          description:
            'Modals should now properly trap focus. It should not be possible to tab-navigate away from a modal.',
          type: 'fix',
        },
        {
          description: 'Modal frame no longer overflows on smaller screens.',
          type: 'fix',
        },
        {
          description:
            'Fullscreen icon is no longer visible on Mobile Safari (because Mobile Safari does not fully support fullscreen API).',
          type: 'fix',
        },
        {
          description: 'Mouse pointer no longer disappears over board gutter.',
          type: 'fix',
        },
      ],
      'Code quality': [
        {
          description: 'More tests',
          type: 'feature',
        },
        {
          description: 'Some util functions now live in their own files',
          type: 'feature',
        },
      ],
    },
  },
  {
    date: new Date('2022-11-04').getTime(),
    title: '1.7.0 - A Veritable Smörgåsbord!',
    description:
      'Electron rendering strategies, better ions, zoom and numerous (if not particularly exciting) minor features and bugfixes.',
    changelog: {
      'Core functionality': [
        {
          description:
            'More than one electron can be added to or removed from an atom.',
          type: 'feature',
        },
      ],
      'UI and visuals': [
        {
          description:
            'There are two electron rendering strategies to choose from: "prioritise pairs" and "prioritise sides". CAPI variable in charge of that is "Sim.ElectronNotation".',
          type: 'feature',
        },
        {
          description: 'Updated copy across the entire UI.',
          type: 'feature',
        },
        {
          description: 'Unlabelled icons now have tooltips.',
          type: 'feature',
        },
        {
          description:
            'Loose electrons are now more consistent with atoms, UI-wise. Attempting to move one electron onto another will result in selection instead of an error.',
          type: 'fix',
        },
        {
          description:
            'Board can now be zoomed in and out in chunky 0.25 increments. Keyboard shortcuts for zoom in/out are "[" and "]", respectively.',
          type: 'feature',
        },
        {
          description: 'Bank/board piece can now be deselected via ESC key.',
          type: 'feature',
        },
        {
          description:
            'There is a confirmation modal whenever user tries to navigate away from the page unless their undo history is empty.',
          type: 'feature',
        },
        {
          description:
            'Loose electrons can be added to atoms directly from the bank (if available)',
          type: 'feature',
        },
        {
          description:
            'Modals and footer content play nicer with lower resolutions.',
          type: 'fix',
        },
        {
          description:
            'Contextual action icons in the toolbar get enabled/disabled when necessary.',
          type: 'fix',
        },
      ],
      Miscellaneous: [
        {
          description: 'Yield arrow is now tied to "Sim.Objective.Yield"',
          type: 'feature',
        },
        {
          description:
            'Loose-electron-in-the-bank can be enabled/disabled via dedicated CAPI variable - "Sim.Bank.IncludeElectron". This makes more sense than configuring it via "Sim.Objects", since its definition should always be included.',
          type: 'feature',
        },
        {
          description:
            'CAPI variable "Sim.Mode" is now supported. It functions as a preset for several other CAPI variables and takes precedence over them unless set to "custom".',
          type: 'feature',
        },
        {
          description:
            'God mode can now be enabled/disabled via "Sim.GodModeEnabled"',
          type: 'feature',
        },
      ],
    },
  },
];
