import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  IFullscreenCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';

export enum CapiLR {
  left = 'Left',
  right = 'Right',
}

export enum CapiYieldType {
  left = 'Left',
  right = 'Right',
  both = 'Both',
  none = 'None',
}

export enum SimMode {
  all = 'All',
  tutorial = 'Tutorial',
  buildM = 'buildM',
  buildR = 'buildR',
  buildP = 'buildP',
  custom = 'Custom',
}

export enum CapiElectronDistributionStrategy {
  prioritisePairs = 'prioritisePairs',
  prioritiseSides = 'prioritiseSides',
}

export interface CapiAtom {
  name: string;
  symbol: string;
  color: string;
  valenceElectrons: number;
  maxPositiveIons: number;
  maxNegativeIons: number;
}

export interface ICapiModel extends IFullscreenCapiModel {
  'Sim.Objective.Selected': CapiLR;

  'Sim.Objective.Left.Enabled': boolean;
  'Sim.Objective.Left.Visible': boolean;
  'Sim.Objective.Left.Text': string;
  'Sim.Objective.Left.Template': string;
  'Sim.Objective.Left.Value': string;

  'Sim.Objective.Right.Enabled': boolean;
  'Sim.Objective.Right.Visible': boolean;
  'Sim.Objective.Right.Text': string;
  'Sim.Objective.Right.Template': string;
  'Sim.Objective.Right.Value': string;

  'Sim.Objective.Yield.Type': CapiYieldType;
  'Sim.Objective.Attempts': number;

  'Sim.Bank.Visible': boolean;
  'Sim.Bank.Enabled': boolean;
  'Sim.Bank.IncludeElectron': boolean;

  'Sim.Board.Initial': string; // NOTE: Encoded molecules, just like in Result section
  'Sim.Board.Size': string; // NOTE: "2x4"

  'Sim.Objects': string; // NOTE: JSON of CapiAtom[]

  'Sim.Bonds.Left.Single.Quantity': number;
  'Sim.Bonds.Left.Double.Quantity': number;
  'Sim.Bonds.Left.Triple.Quantity': number;
  'Sim.Bonds.Left.Quadruple.Quantity': number;
  'Sim.Bonds.Right.Single.Quantity': number;
  'Sim.Bonds.Right.Double.Quantity': number;
  'Sim.Bonds.Right.Triple.Quantity': number;
  'Sim.Bonds.Right.Quadruple.Quantity': number;

  'Sim.Mode': SimMode;
  'Sim.ElectronNotation': CapiElectronDistributionStrategy;
  'Sim.Delete.Enabled': boolean;
  'Sim.Error.Enabled': boolean;

  'Sim.Result.Left.Correct': boolean;
  'Sim.Result.Left.CorrectMolecules': string;
  'Sim.Result.Left.ExpectedMolecules': string;
  'Sim.Result.Left.ExtraneousMolecules': boolean;

  'Sim.Result.Right.Correct': boolean;
  'Sim.Result.Right.CorrectMolecules': string;
  'Sim.Result.Right.ExpectedMolecules': string;
  'Sim.Result.Right.ExtraneousMolecules': boolean;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...FullscreenCapiSchema,
  'Sim.Objective.Selected': {
    initialValue: CapiLR.left,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiLR),
    },
  },
  'Sim.Objective.Left.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Objective.Left.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Objective.Left.Text': {
    initialValue: 'Reactant',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Left.Template': {
    initialValue: '2NH_ + _O2',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Left.Value': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Right.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Objective.Right.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Objective.Right.Text': {
    initialValue: 'Product',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Right.Template': {
    initialValue: '_NO2- + _H_O+',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Right.Value': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Objective.Yield.Type': {
    initialValue: CapiYieldType.right,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiYieldType),
    },
  },
  'Sim.Objective.Attempts': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Bank.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Bank.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Bank.IncludeElectron': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Board.Size': {
    initialValue: '20x15',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Board.Initial': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },

  'Sim.Objects': {
    initialValue:
      '[{"name":"Carbon","symbol":"C","color":"#cce5ee","valenceElectrons":4,"maxPositiveIons":4,"maxNegativeIons":4},{"name":"Hydrogen","symbol":"H","color":"#c9c0ce","valenceElectrons":1,"maxPositiveIons":1,"maxNegativeIons":4},{"name":"Oxygen","symbol":"O","color":"#d1dfd5","valenceElectrons":6,"maxPositiveIons":4,"maxNegativeIons":2},{"name":"Nitrogen","symbol":"N","color":"#ffe8cc","valenceElectrons":5,"maxPositiveIons":4,"maxNegativeIons":3}]',
    options: { type: CAPI_TYPES.STRING },
  },

  'Sim.Bonds.Left.Single.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Left.Double.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Left.Triple.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Left.Quadruple.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Right.Single.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Right.Double.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Right.Triple.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },
  'Sim.Bonds.Right.Quadruple.Quantity': {
    initialValue: 0,
    options: { type: CAPI_TYPES.NUMBER },
  },

  'Sim.ElectronNotation': {
    initialValue: CapiElectronDistributionStrategy.prioritisePairs,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiElectronDistributionStrategy),
    },
  },
  'Sim.Mode': {
    initialValue: SimMode.custom,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(SimMode),
    },
  },
  'Sim.Delete.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Error.Enabled': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Result.Left.Correct': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Result.Left.CorrectMolecules': {
    initialValue: '[false,false]',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Result.Left.ExpectedMolecules': {
    initialValue:
      'H|0|0010|9,2;O|0|1010|9,3;H|0|1000|9,4/H|0|0100|6,4;O|0|0011|7,4;H|0|1000|7,5',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Result.Left.ExtraneousMolecules': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Result.Right.Correct': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Result.Right.CorrectMolecules': {
    initialValue: '[false,false]',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Result.Right.ExpectedMolecules': {
    initialValue:
      'H|0|0010|9,2;O|0|1010|9,3;H|0|1000|9,4/H|0|0100|6,4;O|0|0011|7,4;H|0|1000|7,5',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Result.Right.ExtraneousMolecules': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
