import { Atoms, IBoardPiece } from '../stores/domain';
import { getAtomSymbol } from './get-atom-symbol';
import { getTargetCoords } from './get-target-coords';
import { isIdenticalArray } from './is-identical-array';
import { solutionDecoder } from './solution-encoder';

interface IAtomRelations {
  atom: string;
  bonds: string[];
}

const findRelatedAtom = (
  pieces: IBoardPiece[],
  index: number,
  direction: number
) => {
  const relatedPieceCoords = getTargetCoords(pieces[index].coords, direction);

  const relatedGamePiece = pieces.find((p) =>
    isIdenticalArray(p.coords, relatedPieceCoords)
  )?.piece;

  return relatedGamePiece;
};

const getAtomRelations = (value: string, atoms: Atoms) => {
  const pieces = solutionDecoder(value, atoms);
  const atomRelations: IAtomRelations[] = [];

  for (let i = 0; i < pieces.length; i++) {
    const gamePiece = pieces[i].piece;

    if (!gamePiece) continue;

    atomRelations[i] = {
      atom: getAtomSymbol(gamePiece.atom.symbol, gamePiece.charge),
      bonds: [],
    };

    // Search all directions for related atoms
    for (let d = 0; d < 4; d++) {
      const numberOfBonds = gamePiece?.bonds?.[d];

      if (numberOfBonds === 0) continue;

      const relatedGamePiece = findRelatedAtom(pieces, i, d);

      if (relatedGamePiece) {
        atomRelations[i].bonds.push(
          `${getAtomSymbol(
            relatedGamePiece.atom.symbol,
            relatedGamePiece.charge
          )}/${numberOfBonds}`
        );
      }
    }
  }

  return atomRelations;
};

/**
 * Generic function to compare encoded solutions
 */
export const solutionComparator = (
  inputValue: string,
  correctValue: string,
  atoms: Atoms
) => {
  const inputSet = getAtomRelations(inputValue, atoms);
  const correctSet = getAtomRelations(correctValue, atoms);

  const isLengthEqual = inputSet.length === correctSet.length;

  if (!isLengthEqual) return false;

  const solutionSet = inputSet.map((i) => {
    const index = correctSet.findIndex(
      (c) =>
        c.atom === i.atom && isIdenticalArray(c.bonds.sort(), i.bonds.sort())
    );

    if (index > -1) {
      correctSet.splice(index, 1);
      return true;
    }

    return false;
  });

  return !solutionSet.some((s) => s === false);
};
