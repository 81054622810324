import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

export const Electrons: FC<{ offset: number }> = ({ offset = 0 }) => (
  <g style={{ transform: `translate(18px,${offset - 2.5}px)` }}>
    <circle cx="12.0001" cy="3" r="2" fill="black" />
    <circle cx="3.00006" cy="3" r="2" fill="black" />
  </g>
);

export const Bond: FC<{ count: number }> = ({ count = 1 }) => {
  const width = count > 1 ? 14 * (count * 0.6) : 14;

  return (
    <g>
      <line
        x1="20"
        y1="0.5"
        x2="30"
        y2="0.5"
        stroke="#FCE300"
        strokeWidth={width + 6}
        strokeLinecap="round"
        opacity="0.4"
      />
      <line
        x1="20"
        y1="0.5"
        x2="30"
        y2="0.5"
        stroke="#FCE300"
        strokeWidth={width}
        strokeLinecap="round"
      />
    </g>
  );
};

const rotationByPositionIndex: Record<number, number> = {
  0: 270,
  1: 0,
  2: 90,
  3: 180,
};

export const BondGroup: FC<{
  count: number;
  positionIndex: number;
}> = observer(({ count, positionIndex }) => (
  <g
    style={{
      transform: `translate(25px,25px) rotate(${rotationByPositionIndex[positionIndex]}deg)`,
    }}
  >
    <Bond count={count} />
    {[...new Array(count)].map((_, i) => (
      <Electrons key={i} offset={i * 8 - ((count - 1) / 2) * 8} />
    ))}
  </g>
));
