import React from 'react';
import { Key } from '../Keyboard/Key';
import { Icon } from '../Icon';

export const HELP_CONTENT = {
  Mouse: [
    {
      description: 'Select/deselect bank item',
      action: 'Left click',
    },
    {
      description: 'Select/deselect board item',
      action: 'Left click',
    },
    {
      description: 'Add atom to the board',
      action: (
        <div>
          With atom selected in the bank, left click on an empty square
          (Reactant Board only)
          <br />
          <br />
          For anions, left click + <Key>shift</Key>
          <br />
          For cations, left click + <Key>alt/opt</Key>
        </div>
      ),
    },
    {
      description: 'Move atom/electron',
      action: 'Drag and drop OR select the atom and then click an empty square',
    },
    {
      description: 'Remove atom/electron from the board',
      action: (
        <div>
          Select it, then click{' '}
          <Icon id="trash" className="text-lg text-skyblue" /> (Reactant Board
          only)
        </div>
      ),
    },
    {
      description: 'Create bond',
      action: 'Select atom on the board, then click adjacent atom',
    },
    {
      description: 'Remove bonds',
      action: (
        <div>
          Select atom on the board, then click{' '}
          <Icon id="unlink" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Detach electron',
      action: (
        <div>
          Select atom on the board, then click{' '}
          <Icon id="circlePlus" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Attach electron',
      action: 'Select loose electron on the board, then click an adjacent atom',
    },
    {
      description: 'Undo',
      action: (
        <div>
          Click <Icon id="undo" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Redo',
      action: (
        <div>
          Click <Icon id="redo" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Zoom in',
      action: (
        <div>
          Click <Icon id="zoomIn" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Zoom out',
      action: (
        <div>
          Click <Icon id="zoomOut" className="text-lg text-skyblue" />
        </div>
      ),
    },
  ],
  Touch: [
    {
      description: 'Select/deselect bank item',
      action: 'Tap',
    },
    {
      description: 'Select/deselect board item',
      action: 'Tap',
    },
    {
      description: 'Add atom to the board',
      action:
        'With atom selected in the bank, tap an empty square (Reactant Board only)',
    },
    {
      description: 'Move atom/electron',
      action: 'Drag and drop OR select the atom and then tap an empty square',
    },
    {
      description: 'Remove atom/electron from the board',
      action: (
        <div>
          Select it, then tap{' '}
          <Icon id="trash" className="text-lg text-skyblue" /> (Reactant Board
          only)
        </div>
      ),
    },
    {
      description: 'Create bond',
      action: 'Select atom on the board, then tap adjacent atom',
    },
    {
      description: 'Remove bonds',
      action: (
        <div>
          Select atom on the board, then tap{' '}
          <Icon id="unlink" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Detach electron',
      action: (
        <div>
          Select atom on the board, then tap{' '}
          <Icon id="circlePlus" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Attach electron',
      action: 'Select loose electron on the board, then tap an adjacent atom',
    },
    {
      description: 'Undo',
      action: (
        <div>
          Tap <Icon id="undo" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Redo',
      action: (
        <div>
          Tap <Icon id="redo" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Zoom in',
      action: (
        <div>
          Tap <Icon id="zoomIn" className="text-lg text-skyblue" />
        </div>
      ),
    },
    {
      description: 'Zoom out',
      action: (
        <div>
          Tap <Icon id="zoomOut" className="text-lg text-skyblue" />
        </div>
      ),
    },
  ],
  Keyboard: [
    {
      description: 'Select/deselect bank item',
      action: (
        <div>
          Number row keys <Key>1</Key> - <Key>9</Key>
        </div>
      ),
    },
    {
      description: 'Move board cursor',
      action: (
        <div>
          <Key>W</Key> <Key>S</Key> <Key>A</Key> <Key>D</Key> or <Key>↑</Key>{' '}
          <Key>↓</Key> <Key>←</Key> <Key>→</Key>
        </div>
      ),
    },
    {
      description: 'Select/deselect board item',
      action: (
        <div>
          <Key>space</Key> or <Key>enter</Key>
        </div>
      ),
    },
    {
      description: 'Add atom to the board',
      action: (
        <div>
          With atom selected in the bank, <Key>space</Key> or <Key>enter</Key>{' '}
          (Reactant Board only)
          <br />
          <br />
          For anions, <Key>enter</Key> + <Key>shift</Key>
          <br />
          For cations, <Key>enter</Key> + <Key>alt/opt</Key>
        </div>
      ),
    },
    {
      description: 'Move atom/electron',
      action: (
        <div>
          With atom selected, <Key>W</Key> <Key>S</Key> <Key>A</Key>{' '}
          <Key>D</Key> or <Key>↑</Key> <Key>↓</Key> <Key>←</Key> <Key>→</Key>
        </div>
      ),
    },
    {
      description: 'Remove atom/electron from the board',
      action: (
        <div>
          Select unbonded atom, then <Key>del</Key> (Reactant Board only)
        </div>
      ),
    },
    {
      description: 'Create bond',
      action: (
        <div>
          Select atom on the board, then move board cursor to an adjacent atom
          and <Key>space</Key> or <Key>enter</Key>
        </div>
      ),
    },
    {
      description: 'Remove bonds',
      action: (
        <div>
          Select atom on the board, then <Key>del</Key>
        </div>
      ),
    },
    {
      description: 'Detach electron',
      action: (
        <div>
          Select atom on the board, then <Key>+</Key>
        </div>
      ),
    },
    {
      description: 'Attach electron',
      action: (
        <div>
          Select loose electron on the board, then move board cursor to an
          adjacent atom and <Key>space</Key> or <Key>enter</Key>
        </div>
      ),
    },
    {
      description: 'Undo',
      action: (
        <div>
          <Key>cmd</Key>+<Key>Z</Key>
        </div>
      ),
    },
    {
      description: 'Redo',
      action: (
        <div>
          <Key>cmd</Key>+<Key>shift</Key>+<Key>Z</Key>
        </div>
      ),
    },
    {
      description: 'Zoom in',
      action: (
        <div>
          <Key>{'['}</Key>
        </div>
      ),
    },
    {
      description: 'Zoom out',
      action: (
        <div>
          <Key>{']'}</Key>
        </div>
      ),
    },
  ],
};
