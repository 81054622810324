import React, { CSSProperties, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { ElectronDistributionStrategy, IGamePiece } from '../../stores/domain';
import { LooseElectron } from './LooseElectron';
import { Atom } from './Atom';
import { isElectron } from '../../utils';

interface IGamePieceProps {
  data: IGamePiece;
  className?: string;
  style?: CSSProperties;
  isShadow?: boolean;
  isDragged?: boolean;
  isCue?: boolean;
  eds: ElectronDistributionStrategy;

  onClick?(gamePiece: IGamePiece): void;
}

export const GamePiece: FC<IGamePieceProps> = observer(
  ({
    data,
    className,
    onClick,
    style,
    isShadow = false,
    isDragged = false,
    isCue,
    eds,
  }) => {
    return (
      <g
        style={style}
        className={className}
        pointerEvents="none"
        onClick={() => onClick && onClick(data)}
        opacity={isCue ? 0.3 : undefined}
      >
        {isElectron(data) ? (
          <LooseElectron
            data={data}
            isDragged={isDragged}
            isShadow={isShadow}
          />
        ) : (
          <Atom
            data={data}
            isDragged={isDragged}
            isShadow={isShadow}
            eds={eds}
          />
        )}
      </g>
    );
  }
);
