import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

export interface IKeyboardHintProps {
  children: ReactNode;
  className?: string;
}

export const KeyboardHint: FC<IKeyboardHintProps> = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      'block bg-skyblue-light text-black font-normal text-md rounded-md text-center w-[20px] h-[20px] border border-skyblue leading-[1.2]',
      className
    )}
  >
    {children}
  </div>
);
