import React, { FC } from 'react';
import { IGenericModal, useTabProps } from 'asu-sim-toolkit';

import { strings } from '../../strings';
import { stopPropagation } from '../../utils';
import { ModalButton } from './ModalButton';
import { ModalFrame } from './ModalFrame';

const { buttonOk, buttonCancel, modalTitle } = strings.genericConfirmationModal;

export interface IConfirmationModal extends IGenericModal<boolean> {
  title?: string;
  message?: string;
  confirmLabel?: string;
  denyLabel?: string;
}

export const ConfirmationModal: FC<IConfirmationModal> = ({
  setOverlayClickHandler,
  onResolve,
  title = modalTitle,
  message,
  confirmLabel = buttonOk,
  denyLabel = buttonCancel,
}) => {
  setOverlayClickHandler(() => onResolve(false));

  const getTabProps = useTabProps();

  return (
    <ModalFrame title={title} onCloseClick={() => onResolve(false)}>
      <div className="flex flex-col gap-md p-md">
        <div className="text-md">{message}</div>
        <div
          className="flex gap-md pt-md border-t border-gray-200"
          onKeyDown={stopPropagation}
        >
          <ModalButton
            autoFocus
            className="flex-1"
            onClick={() => onResolve(false)}
            {...getTabProps()}
          >
            {denyLabel}
          </ModalButton>
          <ModalButton
            primary
            className="flex-1"
            onClick={() => onResolve(true)}
            {...getTabProps()}
          >
            {confirmLabel}
          </ModalButton>
        </div>
      </div>
    </ModalFrame>
  );
};
