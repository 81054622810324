import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Bonds,
  ElectronDistributionStrategy,
  IGamePiece,
} from '../../stores/domain';
import { calculateElectronAngles, calculateFreeElectrons } from '../../utils';
import { Electron } from './Electron';
import { IonSign } from './IonSign';

interface IAtomProps {
  data: IGamePiece;
  isShadow?: boolean;
  isDragged?: boolean;
  eds?: ElectronDistributionStrategy;
}

function renderElectrons(
  count: number,
  eds: ElectronDistributionStrategy,
  isShadow?: boolean,
  bonds?: Bonds
) {
  if (count <= 0) return null;

  const angles = calculateElectronAngles(count, bonds || [0, 0, 0, 0], eds);

  return angles.map((angle, index) => (
    <Electron key={index} angle={angle} isShadow={isShadow} />
  ));
}

function renderIonSymbol({ charge, atom }: IGamePiece, isShadow: boolean) {
  if (charge === 0) return null;

  if (Math.abs(charge) === 1)
    return (
      <g transform={`translate(36 13)`}>
        <circle
          cx="0"
          cy="0"
          r="5"
          fill={isShadow ? 'white' : atom.backgroundColor}
          stroke={atom.borderColor}
          strokeDasharray={isShadow ? '2 2' : ''}
        />
        <IonSign x={0} y={0} charge={charge} isShadow={isShadow} />
      </g>
    );

  return (
    <g transform={`translate(32 13)`}>
      <rect
        x="-2"
        y="-6"
        width={17}
        height={13}
        rx="6"
        fill={isShadow ? 'white' : atom.backgroundColor}
        stroke={atom.borderColor}
        strokeDasharray={isShadow ? '2 2' : ''}
      />

      <text
        x="0"
        y="4"
        className="text-[11px] select-none"
        fill={isShadow ? 'silver' : 'auto'}
      >
        {Math.abs(charge)}
      </text>
      <IonSign x={10} y={1} charge={charge} isShadow={isShadow} />
    </g>
  );
}

export const Atom: FC<IAtomProps> = observer(
  ({
    data,
    isShadow = false,
    isDragged = false,
    eds: eds = ElectronDistributionStrategy.prioritisePairs,
  }) => {
    return (
      <>
        {data.isSelected && !isDragged && (
          <circle
            cx="25"
            cy="25"
            r="18"
            stroke="#FCE300"
            strokeWidth="6"
            fill="#FCE300"
            strokeOpacity="0.4"
          />
        )}
        <circle
          cx="25"
          cy="25"
          r="14.5"
          fill={isShadow ? 'white' : data.atom.backgroundColor}
          stroke={isShadow ? 'silver' : data.atom.borderColor}
          strokeDasharray={isShadow ? '2 2' : ''}
        />
        <text
          fill={isShadow ? 'silver' : 'black'}
          fontSize="20"
          fontWeight="800"
          letterSpacing="0em"
          x="25"
          y="32"
          className="select-none"
          textAnchor="middle"
        >
          {data.atom.symbol}
        </text>
        <g>
          {renderElectrons(
            calculateFreeElectrons(data),
            eds,
            isShadow,
            data.bonds
          )}
        </g>
        {renderIonSymbol(data, isShadow)}
      </>
    );
  }
);
