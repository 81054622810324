import classNames from 'classnames';
import React, { FC } from 'react';
import { ReactionInputType } from './types';

interface IReactionInput {
  type: ReactionInputType;
  value: string;
  isDisabled?: boolean;

  onChange(newValue: string): void;
}

export const ReactionInput: FC<IReactionInput> = ({
  type,
  value,
  onChange,
  isDisabled,
}) => {
  return (
    <input
      value={value}
      maxLength={1}
      max={9}
      min={0}
      onKeyDown={(event) => event.stopPropagation()}
      onChange={(event) => onChange(event.target.value)}
      className={classNames(
        'w-7 border-[3px] border-blue-light m-1 text-center text-blue-dark font-semibold appearance-none hover:appearance-none',
        type === ReactionInputType.subscript
          ? 'h-7 text-lg translate-y-1'
          : 'h-10',
        isDisabled && 'cursor-not-allowed'
      )}
      type="number"
      disabled={isDisabled}
    />
  );
};
