import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { ICapiModel } from '../capi';
import { CapiBoundStore } from './capi-bound-store';
import { ControlStore, IControlState } from './control-store';
import { ELECTRON_ID, IAtom, IGamePiece } from './domain';
import { IBankStore, IRootStore } from './types';
import { ICAPI, CAPI_TYPES } from 'asu-sim-toolkit';

export class BankStore
  extends CapiBoundStore<ICapiModel>
  implements IBankStore
{
  private rootStore: IRootStore;

  includeElectron = false;
  bankControlState: IControlState;
  selectedBankPiece: IGamePiece | null = null;
  atoms: IAtom[] = [];
  charge = 0;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    this.bankControlState = new ControlStore(
      capi,
      'Sim.Bank.Enabled',
      'Sim.Bank.Visible'
    );

    makeObservable(this, {
      selectedBankPiece: observable,
      atoms: observable,
      gamePieces: computed,
      charge: observable,
      includeElectron: observable,

      setSelectedBankPiece: action.bound,
      setSelectedBankPieceCharge: action.bound,
      setCharge: action.bound,
    });

    this.synchronizeFromCapi('includeElectron', 'Sim.Bank.IncludeElectron');

    reaction(
      () => [this.rootStore.simulationStore.atoms, this.includeElectron],
      ([atoms, includeElectron], prev) => {
        if (prev) {
          Object.keys(prev[0]).forEach((k) => {
            this.capi.removeField(`Sim.Atoms.Left.${k}`);
            this.capi.removeField(`Sim.Atoms.Right.${k}`);
          });
        }

        this.selectedBankPiece = null;
        this.atoms = Object.values(atoms);

        Object.keys(atoms).forEach((k) => {
          this.capi.addField(`Sim.Atoms.Left.${k}`, 0, {
            type: CAPI_TYPES.NUMBER,
          });
          this.capi.addField(`Sim.Atoms.Right.${k}`, 0, {
            type: CAPI_TYPES.NUMBER,
          });
        });

        if (!includeElectron)
          this.atoms = this.atoms.filter((atom) => atom.symbol != ELECTRON_ID);
      },
      { fireImmediately: true }
    );
  }

  private getPossibleCharge(charge: number): number {
    if (
      this.selectedBankPiece?.atom &&
      this.selectedBankPiece.atom.maxNegativeIons &&
      charge === 1
    ) {
      return 1;
    }

    if (
      this.selectedBankPiece?.atom &&
      this.selectedBankPiece.atom.maxPositiveIons &&
      charge === -1
    ) {
      return -1;
    }

    return 0;
  }

  setSelectedBankPiece(bankPiece: IGamePiece | null) {
    if (this.selectedBankPiece?.atom.name === bankPiece?.atom.name) {
      this.selectedBankPiece = null;
    } else {
      this.selectedBankPiece = bankPiece;
    }
  }

  setSelectedBankPieceCharge(charge: number) {
    if (!this.selectedBankPiece) {
      throw new Error('Cannot set charge if no piece is selected');
    }

    this.selectedBankPiece.charge = this.getPossibleCharge(charge);
  }

  setCharge(charge: number) {
    this.charge = charge;
  }

  get gamePieces() {
    return this.atoms.map((atom) => {
      const isChargeAllowed =
        this.charge === 0
          ? true
          : (this.charge === -1 && atom.maxPositiveIons) ||
            (this.charge === 1 && atom.maxNegativeIons);

      return {
        atom,
        selectedBondIndex: null,
        charge: isChargeAllowed ? this.charge : 0,
        isSelected: false,
        isLocked: !isChargeAllowed,
      };
    });
  }
}
