import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ICapiPanelAction,
  CapiPanel,
  NotificationArea,
  ModalRoot,
} from 'asu-sim-toolkit';

import { useStores } from '../../providers/store/use-stores';
import { Board } from '../Board/Board';
import { Objective } from '../Objective/Objective';
import { Bank } from '../Bank/Bank';
import { getCapi } from '../../capi';
import { isDebugMode } from '../../config';
import { NotificationItem } from '../NotificationItem/NotificationItem';

export const MainView = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    modalStore,
    notificationStore,
    appStore: { registerFullscreenElement, errorBarEnabled },
    bankStore: { bankControlState },
    simulationStore: { exportToCAPI },
    recapStore: { showRecap, hasNewUpdates },
    simContextStore: { forceSimMode, mode },
  } = useStores();

  useEffect(() => {
    if (!wrapperRef.current) return;

    registerFullscreenElement(wrapperRef.current);
  }, [registerFullscreenElement]);

  const debugActions: ICapiPanelAction[] = useMemo(
    () => [
      {
        iconId: hasNewUpdates ? 'newUpdates' : 'info',
        tooltip: 'Version history',
        onClick: () => showRecap(false),
      },
      {
        iconId: 'export',
        tooltip: 'Export',
        onClick: exportToCAPI,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNewUpdates]
  );

  return (
    <div ref={wrapperRef} className="bg-white">
      {errorBarEnabled && (
        <NotificationArea
          notificationStore={notificationStore}
          placement="top-center"
          itemComponent={NotificationItem}
        />
      )}
      <ModalRoot modalStore={modalStore}>
        <div className="h-[100dvh] w-screen overflow-hidden flex">
          {(isDebugMode || mode === 'AUTHOR') && (
            <CapiPanel
              additionalActions={debugActions}
              capi={getCapi()}
              onSimModeChange={forceSimMode}
            />
          )}
          {bankControlState.isVisible && <Bank />}
          <div className="relative h-full w-screen overflow-hidden flex flex-col">
            <Board />
            <Objective />
          </div>
        </div>
      </ModalRoot>
    </div>
  );
});
