import { solutionDecoder } from './solution-encoder';
import { Atoms } from '../stores/domain';

export const getBondsQuantity = (input: string[], atoms: Atoms) => {
  const value = input.join(';');
  const pieces = solutionDecoder(value, atoms);

  let numberOfSingleBonds = 0;
  let numberOfDoubleBonds = 0;
  let numberOfTripleBonds = 0;
  let numberOfQuadrupleBonds = 0;

  for (let i = 0; i < pieces.length; i++) {
    const gamePiece = pieces[i].piece;

    if (!gamePiece) continue;

    for (let d = 0; d < 4; d++) {
      const numberOfBonds = gamePiece?.bonds?.[d];

      if (numberOfBonds === 1) numberOfSingleBonds++;
      else if (numberOfBonds === 2) numberOfDoubleBonds++;
      else if (numberOfBonds === 3) numberOfTripleBonds++;
      else if (numberOfBonds === 4) numberOfQuadrupleBonds++;
    }
  }

  return {
    single: numberOfSingleBonds / 2,
    double: numberOfDoubleBonds / 2,
    triple: numberOfTripleBonds / 2,
    quadruple: numberOfQuadrupleBonds / 2,
  };
};
