import { IGamePiece } from '../stores/domain';
import {
  calculateAllElectrons,
  calculateFreeElectrons,
} from './calculate-electrons';

export const canModifyCharge = (piece: IGamePiece, deltaCharge: number) => {
  const currentCharge = piece.charge + deltaCharge;
  const meetsMaxDeviation =
    currentCharge <= piece.atom.maxNegativeIons &&
    currentCharge >= -piece.atom.maxPositiveIons;

  if (deltaCharge < 0) {
    return calculateFreeElectrons(piece) > 0 && meetsMaxDeviation;
  }

  return calculateAllElectrons(piece) < 8 && meetsMaxDeviation;
};
