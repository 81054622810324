import { makeObservable, observable } from 'mobx';

import { ICAPI } from '../capi-core/types';
import { CapiBoundStore } from './capi-bound-store';

export interface IControlState {
  isEnabled: boolean;
  isVisible: boolean;
}

export class ControlStore<M>
  extends CapiBoundStore<M>
  implements IControlState
{
  isEnabled = true;
  isVisible = true;

  constructor(capi: ICAPI<M>, capiIdEnabled: keyof M, capiIdVisible: keyof M) {
    super(capi);

    makeObservable(this, {
      isEnabled: observable,
      isVisible: observable,
    });

    this.synchronizeFromCapi('isEnabled', capiIdEnabled);
    this.synchronizeFromCapi('isVisible', capiIdVisible);
  }
}
