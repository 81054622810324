export const stopPropagation = (ev: { stopPropagation: () => any }) => {
  ev.stopPropagation();
};

/**
 * Stops propagation of button trigger keypresses (space and return)
 * @param ev
 */
export const stopSpecialPropagation = (ev: {
  key: string;
  stopPropagation: () => any;
}) => {
  if (ev.key === ' ' || ev.key === 'Return' || ev.key === 'Enter') {
    ev.stopPropagation();
  }
};
