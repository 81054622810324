import { CapiAtom } from '../capi';
import { darkenColor } from '../utils';
import { Atoms, IAtom } from './domain';

export const mapAtomFromCapi = (input: CapiAtom): IAtom => ({
  name: input.name,
  symbol: input.symbol,
  backgroundColor: input.color,
  borderColor: darkenColor(input.color),
  valenceElectrons: input.valenceElectrons,
  maxPositiveIons: input.maxPositiveIons,
  maxNegativeIons: input.maxNegativeIons,
});

export const mapAtomsFromCapi = (input: CapiAtom[]): Atoms =>
  input.reduce(
    (acc, capiAtom) => ({
      ...acc,
      [capiAtom.symbol]: mapAtomFromCapi(capiAtom),
    }),
    {}
  );
