import { action, makeObservable, observable } from 'mobx';

import { clamp, isElectron } from '../utils';
import { ControlScheme, IInputStore, InputCommand, IRootStore } from './types';
import { Coords, LR } from './domain';
import { inputMaps } from './input-maps';

export class InputStore implements IInputStore {
  private readonly rootStore: IRootStore;

  activeControlScheme: ControlScheme = ControlScheme.mouse;
  cursorCoords: Coords = [0, 0];
  isCursorVisible = true;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      activeControlScheme: observable,
      isCursorVisible: observable,
      cursorCoords: observable,
      handleInput: action.bound,
      moveCursorTo: action.bound,
      showCursor: action.bound,
      hideCursor: action.bound,
      setActiveControlScheme: action.bound,
    });
  }

  showCursor() {
    this.isCursorVisible = true;
  }

  hideCursor() {
    this.isCursorVisible = false;
  }

  moveCursorTo(coords: Coords) {
    const { width, height } = this.rootStore.simulationStore.board;
    const maxCoords = [width - 1, height - 1];

    this.cursorCoords = [
      clamp(coords[0], 0, maxCoords[0]),
      clamp(coords[1], 0, maxCoords[1]),
    ];
    this.isCursorVisible = true;
  }

  moveCursor(offsets: Coords) {
    if (this.activeControlScheme === ControlScheme.keyboard) {
      document.getElementById('board')?.focus();
    }

    const newCoords: Coords = [
      this.cursorCoords[0] + offsets[0],
      this.cursorCoords[1] + offsets[1],
    ];

    this.moveCursorTo(newCoords);
  }

  handleSelect() {
    const { simulationStore, bankStore } = this.rootStore;
    const { selectedGamePiece } = simulationStore;

    const currentGamePiece = simulationStore.board.getBoardPieceByCoords(
      this.cursorCoords
    )?.piece;

    if (selectedGamePiece?.boardPiece) {
      if (currentGamePiece === selectedGamePiece) {
        simulationStore.board.clearHighlight();
        simulationStore.deselectGamePiece();
        return;
      }

      if (
        !currentGamePiece ||
        (isElectron(selectedGamePiece) && !isElectron(currentGamePiece))
      ) {
        simulationStore.board.clearHighlight();
        simulationStore.moveAtom(selectedGamePiece, this.cursorCoords);
        simulationStore.deselectGamePiece();
        return;
      }

      if (currentGamePiece.boardPiece?.isHighlighted) {
        simulationStore.addBond(
          selectedGamePiece.boardPiece,
          currentGamePiece.boardPiece
        );
        simulationStore.board.clearHighlight();
        simulationStore.deselectGamePiece();
        return;
      }
    }

    if (!currentGamePiece) {
      simulationStore.spawnSelectedAtom(this.cursorCoords);
      simulationStore.deselectGamePiece();
      return;
    }

    if (currentGamePiece) {
      if (
        bankStore.selectedBankPiece &&
        isElectron(bankStore.selectedBankPiece) &&
        !isElectron(currentGamePiece)
      ) {
        simulationStore.addBankElectronToAtom(currentGamePiece);
        return;
      }

      bankStore.setSelectedBankPiece(null);
      simulationStore.setSelectedGamePiece(currentGamePiece);

      if (
        simulationStore.selectedGamePiece &&
        isElectron(simulationStore.selectedGamePiece)
      ) {
        simulationStore.board.clearHighlight();
        return;
      }

      if (simulationStore.selectedGamePiece) {
        simulationStore.board.highlightPossibleBonds(this.cursorCoords);
      }
    }
  }

  handleDelete() {
    const { simulationStore } = this.rootStore;
    const currentGamePiece = simulationStore.board.getBoardPieceByCoords(
      this.cursorCoords
    )?.piece;

    if (!currentGamePiece || !currentGamePiece.boardPiece) {
      return;
    }

    if (currentGamePiece.bonds?.some((n) => n !== 0)) {
      simulationStore.removeBonds(currentGamePiece.boardPiece);
      return;
    }

    if (simulationStore.activeTab !== LR.left) {
      return;
    }

    if (currentGamePiece === simulationStore.selectedGamePiece) {
      simulationStore.deselectGamePiece();
    }
    simulationStore.board.removeAtom(this.cursorCoords);
  }

  handleDetachElectron() {
    const { simulationStore } = this.rootStore;
    const currentGamePiece = simulationStore.board.getBoardPieceByCoords(
      this.cursorCoords
    )?.piece;

    if (!currentGamePiece) {
      return;
    }

    simulationStore.detachElectron(currentGamePiece);
  }

  setActiveControlScheme(controlScheme: ControlScheme) {
    this.activeControlScheme = controlScheme;
  }

  handleCancel() {
    const { simulationStore, bankStore } = this.rootStore;

    if (simulationStore.selectedGamePiece) {
      simulationStore.board.clearHighlight();
      simulationStore.setSelectedGamePiece(null);
    }

    if (bankStore.selectedBankPiece) {
      bankStore.setSelectedBankPiece(null);
    }
  }

  handleZoomIn() {
    this.rootStore.appStore.zoomIn();
  }

  handleZoomOut() {
    this.rootStore.appStore.zoomOut();
  }

  handleInput<T extends ControlScheme>(controlScheme: T, rawInput: string) {
    this.setActiveControlScheme(controlScheme);

    const inputMap = inputMaps[controlScheme];
    const command = inputMap[rawInput];

    if (command === null) {
      console.warn(`Unrecognized input - ${rawInput}`);
      return false;
    }

    const { bankStore, simulationStore } = this.rootStore;

    const isBankEnabled =
      bankStore.bankControlState.isEnabled &&
      simulationStore.activeTab === LR.left;

    switch (command) {
      case InputCommand.Left:
        this.moveCursor([-1, 0]);
        break;
      case InputCommand.Right:
        this.moveCursor([1, 0]);
        break;
      case InputCommand.Up:
        this.moveCursor([0, -1]);
        break;
      case InputCommand.Down:
        this.moveCursor([0, 1]);
        break;
      case InputCommand.BankItem_1:
      case InputCommand.BankItem_2:
      case InputCommand.BankItem_3:
      case InputCommand.BankItem_4:
      case InputCommand.BankItem_5:
      case InputCommand.BankItem_6:
      case InputCommand.BankItem_7:
      case InputCommand.BankItem_8:
      case InputCommand.BankItem_9:
        isBankEnabled &&
          bankStore.setSelectedBankPiece(
            bankStore.gamePieces[command - InputCommand.BankItem_1]
          );
        break;
      case InputCommand.ZoomIn:
        this.handleZoomIn();
        break;
      case InputCommand.ZoomOut:
        this.handleZoomOut();
        break;
      case InputCommand.Select:
        this.handleSelect();
        break;
      case InputCommand.Delete:
        this.handleDelete();
        break;
      case InputCommand.Undo:
        this.rootStore.simulationStore.undo();
        break;
      case InputCommand.Redo:
        this.rootStore.simulationStore.redo();
        break;
      case InputCommand.SwitchToRight:
        this.rootStore.simulationStore.setActiveTab(LR.right);
        break;
      case InputCommand.SwitchToLeft:
        this.rootStore.simulationStore.setActiveTab(LR.left);
        break;
      case InputCommand.SwitchToBoard:
        document.getElementById('board')?.focus();
        break;
      case InputCommand.DetachElectron:
        this.handleDetachElectron();
        break;
      case InputCommand.ShowHelp:
        this.rootStore.appStore.showHelpModal();
        break;
      case InputCommand.Cancel:
        this.handleCancel();
        break;
    }
  }
}
