import { Bonds, ElectronDistributionStrategy } from '../stores/domain';

type ElectronDistributionFunction = (
  electronCount: number,
  bonds: Bonds
) => number[];

export const prioritiseSides: ElectronDistributionFunction = (
  electronCount,
  bonds
) => {
  const possibleDirections = bonds.reduce(
    (acc, bond) => (bond ? acc - 1 : acc),
    4
  );

  const order = [0, 2, 1, 3].reduce<number[]>(
    (acc, direction) => (bonds[direction] ? acc : [...acc, direction]),
    []
  );

  const angles = [];

  for (let i = 0; i < electronCount; i++) {
    const directionIndex = i % possibleDirections;
    const shouldAddOffset = electronCount > possibleDirections + directionIndex;
    const direction = order[directionIndex];

    angles.push(
      (direction - 1) * 90 +
        (shouldAddOffset ? (i >= possibleDirections ? 10 : -10) : 0)
    );
  }

  return angles;
};

export const prioritisePairs: ElectronDistributionFunction = (
  electronCount,
  bonds
) => {
  const order = [1, 3, 0, 2]
    .reduce<number[]>(
      (acc, direction) => (bonds[direction] ? acc : [...acc, direction]),
      []
    )
    .sort((a, b) => bonds[(b + 2) % 4] - bonds[(a + 2) % 4]);

  const angles: number[] = [];
  let electronsLeft = electronCount;

  order.forEach((dir) => {
    if (!electronsLeft) return;
    const electronsToPut = electronsLeft > 1 ? 2 : 1;
    electronsLeft -= electronsToPut;
    let offset;

    for (let i = 0; i < electronsToPut; i++) {
      offset = electronsToPut > 1 ? 10 : 0;
      angles.push(90 * (dir - 1) + offset * (i ? -1 : 1));
    }
  });

  return angles;
};

const strategies: Record<
  ElectronDistributionStrategy,
  ElectronDistributionFunction
> = {
  [ElectronDistributionStrategy.prioritisePairs]: prioritisePairs,
  [ElectronDistributionStrategy.prioritiseSides]: prioritiseSides,
};

export function calculateElectronAngles(
  electronCount: number,
  bonds: Bonds,
  strategy: ElectronDistributionStrategy
) {
  return strategies[strategy](electronCount, bonds);
}
