export const strings = {
  genericConfirmationModal: {
    modalTitle: 'Are you sure?',
    buttonOk: 'Ok',
    buttonCancel: 'Cancel',
  },
  dataExport: {
    modalTitle: 'Export data',
    modalButton: 'Copy to clipboard',
    // NOTE: when we're in an iframe that disallows clipboard API calls
    modalButtonNoPermission: 'Close',
    successNotification: 'Data copied to clipboard',
  },
  // NOTE: modal windows appearing on first switch from/to product board
  boardSwitching: {
    toRight: {
      modalTitle: 'Switching to Product Board',
      modalMessage:
        'Note: In the Product Board you will not be able to add or remove atoms. You may always return to the Reactant Board, but it will revert to the last reactant setup you made.',
      modalButton: 'Onwards!',
    },
    toLeft: {
      modalTitle: 'Back to Reactant Board',
      modalMessage:
        'Note: You are returning to your last setup on the Reactant Board. If you do not change anything on the Reactant Board, you can return to your current Product Board setup. If you do change your reactant, your Product Board will be reset.',
      modalButton: 'Got it!',
    },
  },
  boardReset: {
    modalTitle: 'Are you sure?',
    modalMessage:
      'This will clear the board and delete all items. Your work will not be saved.',
    modalButtonAccept: 'Yes, Delete All',
    modalButtonReject: 'Cancel',
  },
  errors: {
    // NOTE: this isn't really a user-facing string - don't worry about it
    couldNotSetBoardSize: (newValue: string) =>
      `Could not set board size to "${newValue}".`,
    canNotAddElectron: 'Atom cannot accept any more electrons',
    // NOTE: after attempting to move an atom onto an occupied spot
    atomAlreadyExists: 'Atom already exists at this place',
    detachElectronNoRoom: 'There is no room to detach the electron',
    detachElectronLimitReached:
      'Cannot detach any more electrons from the atom',
    addBondNoFreeElectrons:
      'At least one atom does not have enough free electrons',
    // NOTE: You can omit the "${limit}" bit in the message and just go "eight"
    //       or don't mention the limit at all. If the laws of physics change
    //       on us, we'll have bigger problems than one hardcoded value in the app.
    addBondUpperLimitReached: (limit: number) =>
      `The maximum number of shared electrons is ${limit}`,
  },
};
