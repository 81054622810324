import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import {
  ElectronDistributionStrategy,
  IBoardPiece,
  IGamePiece,
} from '../../stores/domain';
import { getElementOffset } from '../../utils';
import { GamePiece } from './GamePiece';

interface IBonds {
  boardPieces: IBoardPiece[];
  draggedGamePiece: IGamePiece | null;
  eds: ElectronDistributionStrategy;
}

export const GamePieces: FC<IBonds> = observer(
  ({ boardPieces, draggedGamePiece, eds }) => {
    const result = [];

    for (let i = 0; i <= boardPieces.length - 1; i++) {
      const gamePiece = boardPieces[i].piece;

      if (gamePiece) {
        const [x, y] = getElementOffset(boardPieces[i]);

        result.push(
          <GamePiece
            key={`${x}/${y}`}
            style={{ transform: `translate(${x}px, ${y}px)` }}
            data={gamePiece}
            isShadow={gamePiece.boardPiece === draggedGamePiece?.boardPiece}
            className="atom-blur-in"
            eds={eds}
          />
        );
      }
    }

    return <>{result}</>;
  }
);
