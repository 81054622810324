import React, { FunctionComponent, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon } from '../Icon';
import { stopSpecialPropagation } from '../../utils';
import { IconId } from '../../icon-definitions';

interface IToolbarProps {
  items: (IToolbarItem | Separator)[];
  isDisabled?: boolean;
}

export interface IToolbarItem {
  id: string;
  tip: string;
  hint?: string;
  iconId: IconId;
  isDisabled?: boolean;
  isHidden?: boolean;

  onClick(): void;
}

export const SEPARATOR = 'separator';
export type Separator = { id: typeof SEPARATOR };

export const Toolbar: FunctionComponent<IToolbarProps> = ({ items }) => {
  return (
    <div className="h-10 p-2.5 bg-white rounded-t absolute shadow-outer bottom-0 left-1/2 -translate-x-1/2 z-25 flex  items-center justify-between cursor-default gap-sm sm:gap-md">
      {(items as IToolbarItem[]).map((item) =>
        item.id === SEPARATOR ? (
          <div
            key={item.id}
            className="text-gray-300 border border-x-1 h-full"
          />
        ) : (
          <Fragment key={item.id}>
            {!item.isDisabled && (
              <ReactTooltip
                id={item.id}
                effect="solid"
                type="dark"
                className="whitespace-nowrap text-center"
              >
                {item.tip}
                {item.hint && (
                  <div className="text-gray-300 text-xs text-center leading-[1em] pb-0.5">
                    {`(${item.hint})`}
                  </div>
                )}
              </ReactTooltip>
            )}
            <button
              data-tip
              data-for={item.id}
              disabled={item.isDisabled}
              onKeyDown={stopSpecialPropagation}
              onClick={item.onClick}
              key={item.id}
              className="rounded-sm min-w-[25px] text-gray-400 disabled:opacity-50 focus:outline-none focus:ring focus:ring-keyboardCue "
            >
              <Icon id={item.iconId} />
            </button>
          </Fragment>
        )
      )}
    </div>
  );
};
