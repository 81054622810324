import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import { CapiAtom, ICapiModel } from '../capi';
import {
  canModifyCharge,
  compareMoleculeSets,
  getShortBoardPieces,
  solutionDecoder,
  solutionEncoder,
  getIfGamePieceBondsExist,
  isElectron,
  getIfBoardHasPieces,
  getBondsQuantity,
} from '../utils';
import { ExportModal } from '../components/Modals/ExportModal';
import { strings } from '../strings';
import { IBoardStore, IRootStore, ISimulationStore } from './types';
import { CapiBoundStore } from './capi-bound-store';
import { BoardStore } from './board-store';
import {
  Atoms,
  Coords,
  ELECTRON_DEFINITION,
  ELECTRON_ID,
  IBoardPiece,
  IGamePiece,
  IShortBoardPiece,
  LR,
  YieldType,
} from './domain';
import { confirm, info } from './modals';
import { mapAtomsFromCapi } from './mappers';
import { ICAPI, NotificationType } from 'asu-sim-toolkit';

export class SimulationStore
  extends CapiBoundStore<ICapiModel>
  implements ISimulationStore
{
  rootStore: IRootStore;
  activeTab: LR = LR.left;
  board: IBoardStore = new BoardStore(8, 8);
  boardSize = '8x8';
  atoms: Atoms = {};
  capiAtoms = '[]';

  leftObjectiveSlots: number[] = [];
  leftObjectiveEnabled = true;
  leftObjectiveVisible = true;
  leftObjectiveText = '';
  leftObjectiveTemplate = '';

  rightObjectiveSlots: number[] = [];
  rightObjectiveEnabled = true;
  rightObjectiveVisible = true;
  rightObjectiveText = '';
  rightObjectiveTemplate = '';

  leftSingleBondsQuantity = 0;
  leftDoubleBondsQuantity = 0;
  leftTripleBondsQuantity = 0;
  leftQuadrupleBondsQuantity = 0;
  rightSingleBondsQuantity = 0;
  rightDoubleBondsQuantity = 0;
  rightTripleBondsQuantity = 0;
  rightQuadrupleBondsQuantity = 0;

  isLeftCorrect = false;
  areLeftMoleculesCorrect = '';
  expectedLeftMolecules: string[] = [];
  extraneousLeftMolecules = false;

  isRightCorrect = false;
  areRightMoleculesCorrect = '';
  expectedRightMolecules: string[] = [];
  extraneousRightMolecules = false;

  cueCoords: Coords | null = null;

  yieldType: YieldType = YieldType.right;

  historyStack: Record<LR, IShortBoardPiece[][]> = {
    [LR.left]: [[]],
    [LR.right]: [[]],
  };
  activeHistoryIndex: Record<LR, number> = {
    [LR.left]: 0,
    [LR.right]: 0,
  };
  attempts = 0;

  selectedGamePiece: IGamePiece | null = null;
  draggedGamePiece: IGamePiece | null = null;
  dragTimeout: NodeJS.Timeout | null = null;

  private savedLeftBoardState: IShortBoardPiece[] = [];
  private savedRightBoardState: IShortBoardPiece[] = [];
  private isBoardChanged = false;
  private issuedTips = {
    beforeRightBoard: false,
    afterReturningToLeftBoard: false,
  };

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    makeObservable(this, {
      leftObjectiveSlots: observable,
      leftObjectiveEnabled: observable,
      leftObjectiveVisible: observable,
      leftObjectiveText: observable,
      leftObjectiveTemplate: observable,
      leftObjectiveValue: computed,

      rightObjectiveSlots: observable,
      rightObjectiveEnabled: observable,
      rightObjectiveVisible: observable,
      rightObjectiveText: observable,
      rightObjectiveTemplate: observable,
      rightObjectiveValue: computed,

      leftSingleBondsQuantity: observable,
      leftDoubleBondsQuantity: observable,
      leftTripleBondsQuantity: observable,
      leftQuadrupleBondsQuantity: observable,

      rightSingleBondsQuantity: observable,
      rightDoubleBondsQuantity: observable,
      rightTripleBondsQuantity: observable,
      rightQuadrupleBondsQuantity: observable,

      atoms: observable,
      capiAtoms: observable,

      isLeftCorrect: observable,
      areLeftMoleculesCorrect: observable,
      expectedLeftMolecules: observable,
      extraneousLeftMolecules: observable,

      isRightCorrect: observable,
      areRightMoleculesCorrect: observable,
      expectedRightMolecules: observable,
      extraneousRightMolecules: observable,

      attempts: observable,

      activeHistoryIndex: observable,
      isUndoDisabled: computed,
      isRedoDisabled: computed,
      isUnlinkDisabled: computed,
      isTrashDisabled: computed,

      moveAtom: action,

      selectedGamePiece: observable,
      draggedGamePiece: observable,

      setObjectiveSlot: action.bound,
      setObjectiveTemplate: action,

      spawnSelectedAtom: action.bound,
      addElectronToAtom: action,
      addBankElectronToAtom: action,
      deselectGamePiece: action,
      setSelectedGamePiece: action.bound,
      handleBoardPieceDrag: action.bound,
      handleBoardPieceDrop: action.bound,

      boardSize: observable,
      resizeBoard: action.bound,
      setAtoms: action.bound,

      addBoardToHistoryStack: action.bound,
      activateBoardFromHistoryStack: action.bound,
      undo: action.bound,
      redo: action.bound,

      addBond: action.bound,
      removeSelectedBonds: action.bound,

      detachElectron: action.bound,
      detachElectronFromSelectedAtom: action.bound,

      cue: computed,

      activeTab: observable,
      setActiveTab: flow.bound,

      yieldType: observable,

      clear: action.bound,

      exportToCAPI: action.bound,

      reset: action,
    });

    this.bindToCapi('leftObjectiveEnabled', 'Sim.Objective.Left.Enabled');
    this.bindToCapi('leftObjectiveVisible', 'Sim.Objective.Left.Visible');
    this.bindToCapi('leftObjectiveText', 'Sim.Objective.Left.Text');
    this.synchronizeToCapi('leftObjectiveValue', 'Sim.Objective.Left.Value');

    this.bindToCapi('rightObjectiveEnabled', 'Sim.Objective.Right.Enabled');
    this.bindToCapi('rightObjectiveVisible', 'Sim.Objective.Right.Visible');
    this.bindToCapi('rightObjectiveText', 'Sim.Objective.Right.Text');
    this.synchronizeToCapi('rightObjectiveValue', 'Sim.Objective.Right.Value');

    this.bindToCapi(
      'leftSingleBondsQuantity',
      'Sim.Bonds.Left.Single.Quantity'
    );
    this.bindToCapi(
      'leftDoubleBondsQuantity',
      'Sim.Bonds.Left.Double.Quantity'
    );
    this.bindToCapi(
      'leftTripleBondsQuantity',
      'Sim.Bonds.Left.Triple.Quantity'
    );
    this.bindToCapi(
      'leftQuadrupleBondsQuantity',
      'Sim.Bonds.Left.Quadruple.Quantity'
    );
    this.bindToCapi(
      'rightSingleBondsQuantity',
      'Sim.Bonds.Right.Single.Quantity'
    );
    this.bindToCapi(
      'rightDoubleBondsQuantity',
      'Sim.Bonds.Right.Double.Quantity'
    );
    this.bindToCapi(
      'rightTripleBondsQuantity',
      'Sim.Bonds.Right.Triple.Quantity'
    );
    this.bindToCapi(
      'rightQuadrupleBondsQuantity',
      'Sim.Bonds.Right.Quadruple.Quantity'
    );

    this.synchronizeToCapi('isLeftCorrect', 'Sim.Result.Left.Correct');
    this.synchronizeToCapi(
      'areLeftMoleculesCorrect',
      'Sim.Result.Left.CorrectMolecules'
    );
    this.synchronizeFromCapi(
      'expectedLeftMolecules',
      'Sim.Result.Left.ExpectedMolecules',
      (input) => input.split('/')
    );
    this.synchronizeToCapi(
      'extraneousLeftMolecules',
      'Sim.Result.Left.ExtraneousMolecules'
    );
    this.synchronizeToCapi('isRightCorrect', 'Sim.Result.Right.Correct');
    this.synchronizeToCapi(
      'areRightMoleculesCorrect',
      'Sim.Result.Right.CorrectMolecules'
    );
    this.synchronizeFromCapi(
      'expectedRightMolecules',
      'Sim.Result.Right.ExpectedMolecules',
      (input) => input.split('/')
    );
    this.synchronizeToCapi(
      'extraneousRightMolecules',
      'Sim.Result.Right.ExtraneousMolecules'
    );

    this.synchronizeToCapi('attempts', 'Sim.Objective.Attempts');

    this.synchronizeFromCapi('yieldType', 'Sim.Objective.Yield.Type');

    this.bindToCapi('activeTab', 'Sim.Objective.Selected');

    this.bindToCapi('capiAtoms', 'Sim.Objects');
    this.onCapi('Sim.Objects', (objects: string) => {
      const capiAtoms = JSON.parse(objects) as CapiAtom[];
      this.setAtoms(capiAtoms);
    });

    this.onCapi('Sim.Objective.Left.Template', (newTemplate) => {
      this.setObjectiveTemplate(LR.left, newTemplate);
    });

    this.onCapi('Sim.Objective.Right.Template', (newTemplate) => {
      this.setObjectiveTemplate(LR.right, newTemplate);
    });

    this.bindToCapi('boardSize', 'Sim.Board.Size');
    this.onCapi('Sim.Board.Size', (newValue: string) => {
      this.resizeBoard(newValue);
    });
  }

  setObjectiveTemplate(side: LR, newTemplate: string) {
    side === LR.left
      ? (this.leftObjectiveTemplate = newTemplate)
      : (this.rightObjectiveTemplate = newTemplate);

    const templateSlots = newTemplate
      .split('')
      .reduce<number[]>((acc, t) => (t === '_' ? [...acc, 0] : acc), []);

    side === LR.left
      ? (this.leftObjectiveSlots = templateSlots)
      : (this.rightObjectiveSlots = templateSlots);
  }

  init() {
    const { width, height } = this.board;

    this.rootStore.inputStore.moveCursorTo([
      Math.round(width / 2),
      Math.round(height / 2),
    ]);

    reaction(
      () => this.rootStore.bankStore.selectedBankPiece,
      (selectedBankPiece) => {
        if (!selectedBankPiece) {
          return;
        }
        this.deselectGamePiece();
        this.board.clearHighlight();
      }
    );

    reaction(
      () => this.board.boardVersionId,
      () => {
        this.checkSolution();
        this.updateStats();
        this.addBoardToHistoryStack(this.board);
        this.isBoardChanged = true;
      }
    );

    this.onCapi('Sim.Board.Initial', (newValue = '') => {
      if (!newValue) return;
      const encodedMolecules: string[] = newValue.split('/');
      const shortBoardPieces = encodedMolecules.reduce<IShortBoardPiece[]>(
        (acc, m) => {
          return [
            ...acc,
            ...getShortBoardPieces(solutionDecoder(m, this.atoms)),
          ];
        },
        []
      );
      this.board.replaceBoardContent(shortBoardPieces);
      this.checkSolution();
      this.updateStats();
      this.addBoardToHistoryStack(this.board);
    });
  }

  resizeBoard(value: string) {
    try {
      const [w, h] = value.split('x').map(Number);
      this.boardSize = value;
      this.board.resize(w, h);
    } catch (err) {
      this.rootStore.notificationStore.addNotification(
        strings.errors.couldNotSetBoardSize(value),
        {
          type: NotificationType.error,
        }
      );
    }
  }

  setAtoms(capiAtoms: CapiAtom[]) {
    this.board.reset();
    this.reset();
    this.atoms = mapAtomsFromCapi(capiAtoms);
    this.atoms[ELECTRON_ID] = ELECTRON_DEFINITION;
  }

  *setActiveTab(newTab: LR) {
    if (this.activeTab === newTab) return;

    this.board.clearHighlight();
    this.setSelectedGamePiece(null);
    this.rootStore.bankStore.setSelectedBankPiece(null);
    this.activeTab = newTab;
    this.attempts++;

    if (newTab === LR.right) {
      if (!this.issuedTips.beforeRightBoard) {
        const { modalMessage, modalButton, modalTitle } =
          strings.boardSwitching.toRight;

        yield info(
          this.rootStore.modalStore,
          modalTitle,
          modalMessage,
          modalButton
        );
        this.issuedTips.beforeRightBoard = true;
      }

      if (this.isBoardChanged) {
        // TODO: See if this couldn't be ripped from undo history.
        this.savedLeftBoardState = getShortBoardPieces(this.board.boardPieces);
        this.isBoardChanged = false;

        // NOTE: Remove right board history
        this.historyStack[LR.right].splice(0);
        this.addBoardToHistoryStack(this.board);
        this.activeHistoryIndex[LR.right] = 0;

        this.updateStats();
      } else {
        this.board.replaceBoardContent(this.savedRightBoardState);
      }
      return;
    }

    if (newTab === LR.left) {
      this.savedRightBoardState = getShortBoardPieces(this.board.boardPieces);
      this.board.replaceBoardContent(this.savedLeftBoardState);
      this.isBoardChanged = false;

      if (!this.issuedTips.afterReturningToLeftBoard) {
        const { modalMessage, modalTitle, modalButton } =
          strings.boardSwitching.toLeft;

        yield info(
          this.rootStore.modalStore,
          modalTitle,
          modalMessage,
          modalButton
        );
        this.issuedTips.afterReturningToLeftBoard = true;
      }
    }
  }

  private updateAtomCount() {
    const atomCount = this.board.getAtomCount();
    const infix = this.activeTab === LR.left ? 'Left' : 'Right';

    const bankAtomSymbols = this.rootStore.bankStore.atoms.map((a) => a.symbol);
    if (!bankAtomSymbols.includes(ELECTRON_ID)) {
      bankAtomSymbols.push(ELECTRON_ID);
    }

    bankAtomSymbols.forEach((atomId) => {
      const key = `Sim.Atoms.${infix}.${atomId}` as keyof ICapiModel;
      if (!Object.keys(this.capi.schema || {}).includes(key)) return;

      const count = atomCount[atomId] || 0;
      if (this.capi.get(key) === count) return;

      this.capi.set(key, count);
    });
  }

  private updateStats() {
    this.updateBondsQuantity();
    this.updateAtomCount();
  }

  private updateBondsQuantity() {
    if (this.activeTab === LR.left) {
      const bondsQuantity = getBondsQuantity(this.export(), this.atoms);
      this.leftSingleBondsQuantity = bondsQuantity.single;
      this.leftDoubleBondsQuantity = bondsQuantity.double;
      this.leftTripleBondsQuantity = bondsQuantity.triple;
      this.leftQuadrupleBondsQuantity = bondsQuantity.quadruple;
    } else {
      const bondsQuantity = getBondsQuantity(this.export(), this.atoms);
      this.rightSingleBondsQuantity = bondsQuantity.single;
      this.rightDoubleBondsQuantity = bondsQuantity.double;
      this.rightTripleBondsQuantity = bondsQuantity.triple;
      this.rightQuadrupleBondsQuantity = bondsQuantity.quadruple;
    }
  }

  private checkSolution() {
    if (this.activeTab === LR.right) {
      const { areMoleculesCorrect, extraneousMolecules } = compareMoleculeSets(
        this.export(),
        this.expectedRightMolecules,
        this.atoms
      );

      this.isRightCorrect =
        areMoleculesCorrect.every(Boolean) && !extraneousMolecules;
      this.areRightMoleculesCorrect = JSON.stringify(areMoleculesCorrect);
      this.extraneousRightMolecules = extraneousMolecules;
    } else {
      const { areMoleculesCorrect, extraneousMolecules } = compareMoleculeSets(
        this.export(),
        this.expectedLeftMolecules,
        this.atoms
      );

      this.isLeftCorrect =
        areMoleculesCorrect.every(Boolean) && !extraneousMolecules;
      this.areLeftMoleculesCorrect = JSON.stringify(areMoleculesCorrect);
      this.extraneousLeftMolecules = extraneousMolecules;
    }
  }

  setObjectiveSlot(side: LR, slotIndex: number, value: string): void {
    side === LR.left
      ? (this.leftObjectiveSlots[slotIndex] = Number(value) || 0)
      : (this.rightObjectiveSlots[slotIndex] = Number(value) || 0);
  }

  get leftObjectiveValue(): string {
    const slots = [...this.leftObjectiveSlots];

    return this.leftObjectiveTemplate
      .split('')
      .map((letter) => (letter === '_' ? slots.shift() || '_' : letter))
      .join('');
  }

  get rightObjectiveValue(): string {
    const slots = [...this.rightObjectiveSlots];

    return this.rightObjectiveTemplate
      .split('')
      .map((letter) => (letter === '_' ? slots.shift() || '_' : letter))
      .join('');
  }

  deselectGamePiece() {
    if (!this.selectedGamePiece) {
      return;
    }
    this.selectedGamePiece.isSelected = false;
    this.selectedGamePiece = null;
  }

  setSelectedGamePiece(piece: IGamePiece | null) {
    const prevSelectedGamePiece = this.selectedGamePiece;

    this.deselectGamePiece();

    if (piece === null || piece === prevSelectedGamePiece) {
      return;
    }

    piece.isSelected = true;
    this.selectedGamePiece = piece;
  }

  spawnSelectedAtom(coords: Coords) {
    const selectedBankPiece = this.rootStore.bankStore.selectedBankPiece;
    if (!selectedBankPiece) {
      return;
    }
    const { atom, charge } = selectedBankPiece;

    try {
      this.board.putAtom(coords, atom, charge);
    } catch (err: any) {
      this.rootStore.notificationStore.addNotification(err.message || err, {
        type: NotificationType.error,
      });
    }
  }

  addBond(boardPiece1: IBoardPiece, boardPiece2: IBoardPiece) {
    try {
      this.board.addBond(boardPiece1, boardPiece2);
    } catch (err: any) {
      this.rootStore.notificationStore.addNotification(err.message || err, {
        type: NotificationType.error,
      });
    }
  }

  removeBonds(boardPiece: IBoardPiece) {
    try {
      this.board.removeBonds(boardPiece);
    } catch (err: any) {
      this.rootStore.notificationStore.addNotification(err.message || err, {
        type: NotificationType.error,
      });
    }
  }

  removeSelectedBonds() {
    if (!this.selectedGamePiece || !this.selectedGamePiece.boardPiece) {
      return;
    }

    this.removeBonds(this.selectedGamePiece.boardPiece);
    this.board.clearHighlight();
    this.setSelectedGamePiece(null);
  }

  addBankElectronToAtom(targetGamePiece: IGamePiece) {
    const isElectronAdded = this.addElectronToAtom(targetGamePiece);

    if (isElectronAdded) {
      this.board.incrementBoardVersion();
    }
  }

  addElectronToAtom(targetGamePiece: IGamePiece) {
    if (!canModifyCharge(targetGamePiece, 1)) {
      this.rootStore.notificationStore.addNotification(
        'The target atom cannot accept any more electrons',
        {
          type: NotificationType.error,
        }
      );

      return false;
    }

    targetGamePiece.charge++;

    return true;
  }

  moveAtom(piece: IGamePiece, coords: Coords) {
    const targetBoardPiece = this.board.getBoardPieceByCoords(coords);
    const targetGamePiece = targetBoardPiece?.piece;
    const isTargetOccupied = Boolean(targetGamePiece);

    if (
      isElectron(piece) &&
      targetGamePiece &&
      targetGamePiece !== piece &&
      !isElectron(targetGamePiece)
    ) {
      const isElectronAdded = this.addElectronToAtom(targetGamePiece);

      if (piece.boardPiece && isElectronAdded) {
        this.board.removeAtom(piece.boardPiece?.coords);
      }

      return;
    }

    if (isTargetOccupied && targetBoardPiece !== piece.boardPiece) {
      this.rootStore.notificationStore.addNotification(
        strings.errors.atomAlreadyExists,
        {
          type: NotificationType.error,
        }
      );

      return;
    }

    if (piece?.boardPiece && !isTargetOccupied) {
      this.board.moveAtom(piece.boardPiece.coords, coords);
      this.deselectGamePiece();
    }
  }

  detachElectron(gamePiece: IGamePiece) {
    if (!gamePiece.boardPiece) {
      return;
    }

    try {
      this.board.detachElectron(gamePiece);
      this.deselectGamePiece();
    } catch (err: any) {
      this.rootStore.notificationStore.addNotification(err.message || err, {
        type: NotificationType.error,
      });
    }
  }

  detachElectronFromSelectedAtom() {
    if (!this.selectedGamePiece || !this.selectedGamePiece.boardPiece) {
      return;
    }

    this.detachElectron(this.selectedGamePiece);
  }

  handleBoardPieceDrag(coords: Coords) {
    const boardPiece = this.board.getBoardPieceByCoords(coords);
    const gamePiece = boardPiece?.piece;

    if (gamePiece) {
      if (
        (this.rootStore.bankStore.selectedBankPiece &&
          !isElectron(this.rootStore.bankStore.selectedBankPiece)) ||
        isElectron(gamePiece)
      ) {
        this.rootStore.bankStore.setSelectedBankPiece(null);
      }

      this.dragTimeout = setTimeout(() => {
        runInAction(() => {
          this.draggedGamePiece = gamePiece;
        });
      }, 150);
    }
  }

  handleBoardPieceDrop(coords: Coords) {
    const draggedPiece = this.draggedGamePiece?.boardPiece?.piece;

    this.draggedGamePiece = null;
    if (this.dragTimeout) clearTimeout(this.dragTimeout);

    if (draggedPiece) {
      this.moveAtom(draggedPiece, coords);
    }
  }

  addBoardToHistoryStack(board: IBoardStore) {
    const activeHistoryIndex = this.activeHistoryIndex[this.activeTab];
    const historyStack = this.historyStack[this.activeTab];
    if (activeHistoryIndex < historyStack.length - 1) {
      // Removes all history after activeHistoryIndex
      historyStack.length = activeHistoryIndex + 1;
    }

    historyStack.push(getShortBoardPieces(board.boardPieces));

    this.activeHistoryIndex[this.activeTab] = historyStack.length - 1;
  }

  activateBoardFromHistoryStack(index: number) {
    this.board.replaceBoardContent(this.historyStack[this.activeTab][index]);
  }

  undo() {
    if (this.isUndoDisabled) return;

    this.board.clearHighlight();
    this.deselectGamePiece();
    this.activeHistoryIndex[this.activeTab] =
      this.activeHistoryIndex[this.activeTab] - 1;
    this.activateBoardFromHistoryStack(this.activeHistoryIndex[this.activeTab]);
    this.isBoardChanged = true;
    this.checkSolution();
    this.updateStats();
  }

  redo() {
    if (this.isRedoDisabled) return;

    this.board.clearHighlight();
    this.deselectGamePiece();
    this.activeHistoryIndex[this.activeTab] =
      this.activeHistoryIndex[this.activeTab] + 1;
    this.activateBoardFromHistoryStack(this.activeHistoryIndex[this.activeTab]);
    this.isBoardChanged = true;
    this.checkSolution();
    this.updateStats();
  }

  get isUndoDisabled() {
    return this.activeHistoryIndex[this.activeTab] === 0;
  }

  get isRedoDisabled() {
    return (
      this.historyStack[this.activeTab].length === 0 ||
      this.activeHistoryIndex[this.activeTab] ===
        this.historyStack[this.activeTab].length - 1
    );
  }

  get isUnlinkDisabled() {
    return !getIfGamePieceBondsExist(this.selectedGamePiece);
  }

  get isTrashDisabled() {
    return !getIfBoardHasPieces(this.board.boardPieces);
  }

  async clear() {
    if (this.selectedGamePiece?.boardPiece) {
      this.board.removeAtom(this.selectedGamePiece.boardPiece?.coords);
      this.selectedGamePiece = null;
      return;
    }

    const { modalTitle, modalMessage, modalButtonAccept, modalButtonReject } =
      strings.boardReset;

    if (
      !(await confirm(
        this.rootStore.modalStore,
        modalTitle,
        modalMessage,
        modalButtonAccept,
        modalButtonReject
      ))
    ) {
      return;
    }

    runInAction(() => {
      this.board.reset();
    });
  }

  export() {
    return this.board
      .getMolecules()
      .map((gamePieces) =>
        solutionEncoder(gamePieces.map((b) => b.boardPiece as IBoardPiece))
      );
  }

  async exportToCAPI() {
    await this.rootStore.modalStore.modal(ExportModal, {
      exportedString: this.export().join('/'),
    });
  }

  get cue() {
    return this.rootStore.bankStore.selectedBankPiece;
  }

  reset() {
    this.historyStack = {
      [LR.left]: [[]],
      [LR.right]: [[]],
    };
    this.activeHistoryIndex = {
      [LR.left]: 0,
      [LR.right]: 0,
    };
    this.selectedGamePiece = null;
    this.draggedGamePiece = null;
    this.dragTimeout = null;
  }
}
