import React, { FC, useEffect, useState } from 'react';
import { IGenericModal } from 'asu-sim-toolkit';

import { ModalFrame } from './ModalFrame';
import { HELP_CONTENT } from './consts';
import classNames from 'classnames';

export enum InputControls {
  mouse = 'Mouse',
  touch = 'Touch',
  keyboard = 'Keyboard',
}

export interface IHelpModal extends IGenericModal<void> {
  title: string;
  message: string;
}

export const HelpModal: FC<IHelpModal> = ({
  setOverlayClickHandler,
  onResolve,
  title,
}) => {
  setOverlayClickHandler(() => onResolve());
  const [activeTab, setActiveTab] = useState(InputControls.mouse);

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onResolve();
      }
    };
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [onResolve]);

  const applyButtonClasses = (tab: InputControls) =>
    classNames(
      'w-full h-full py-sm rounded-t-lg border mx-sm relative font-semibold text-blue',
      tab === activeTab
        ? 'h-[calc(100% + 1px)] top-px bg-white border-gray-400 border-b-white'
        : 'bg-gray-100'
    );

  return (
    <ModalFrame title={title} onCloseClick={() => onResolve()} maxWidth={1000}>
      <div className="basis-11 w-full text-lg flex items-end justify-center select-none border-b border-b-gray-400 pt-md px-md">
        <button
          onClick={() => setActiveTab(InputControls.mouse)}
          className={applyButtonClasses(InputControls.mouse)}
        >
          Mouse
        </button>
        <button
          onClick={() => setActiveTab(InputControls.touch)}
          className={applyButtonClasses(InputControls.touch)}
        >
          Touch
        </button>
        <button
          onClick={() => setActiveTab(InputControls.keyboard)}
          className={applyButtonClasses(InputControls.keyboard)}
        >
          Keyboard
        </button>
      </div>
      <div className="p-md overflow-y-auto" tabIndex={0}>
        {HELP_CONTENT[activeTab].map((item, key) => (
          <div
            key={`${key}-${item.description}`}
            className={classNames(
              'w-full grid grid-cols-[1fr_1fr] border-b border-b-gray-400 py-3 last:border-b-0'
            )}
          >
            <div>{item.description}</div>
            <div>{item.action}</div>
          </div>
        ))}
      </div>
    </ModalFrame>
  );
};
