import { Coords } from '../stores/domain';

const directionOffsets = [
  [0, -1],
  [1, 0],
  [0, 1],
  [-1, 0],
];

export const getTargetCoords = (coords: Coords, direction: number): Coords => [
  coords[0] + directionOffsets[direction][0],
  coords[1] + directionOffsets[direction][1],
];
