import { ToolkitIconId } from 'asu-sim-toolkit';

export const iconDefinitions = {
  chevronRight: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M8.578 16.594l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"/>
    `,
  },
  bug: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M14.016 12v-2.016h-4.031v2.016h4.031zM14.016 15.984v-1.969h-4.031v1.969h4.031zM20.016 8.016v1.969h-2.109q0.094 0.656 0.094 1.031v0.984h2.016v2.016h-2.016v0.984q0 0.328-0.094 0.984h2.109v2.016h-2.813q-0.797 1.359-2.18 2.18t-3.023 0.82-3.023-0.82-2.18-2.18h-2.813v-2.016h2.109q-0.094-0.656-0.094-0.984v-0.984h-2.016v-2.016h2.016v-0.984q0-0.375 0.094-1.031h-2.109v-1.969h2.813q0.703-1.172 1.828-1.969l-1.641-1.641 1.406-1.406 2.203 2.156q0.703-0.141 1.406-0.141t1.406 0.141l2.203-2.156 1.406 1.406-1.641 1.641q1.172 0.797 1.828 1.969h2.813z" />
    `,
  },
  close: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z" />
    `,
  },
  unlink: {
    viewBox: '0 0 26 28',
    content: `
      <path fill="currentColor" d="M6.859 19.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719zM9.5 20.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM6 17c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM25.75 19c0 1.203-0.469 2.328-1.328 3.172l-2.297 2.281c-0.844 0.844-1.969 1.297-3.172 1.297s-2.344-0.469-3.187-1.328l-5.219-5.234c-0.266-0.266-0.469-0.562-0.656-0.875l3.734-0.281 4.266 4.281c0.562 0.562 1.563 0.578 2.125 0.016l2.297-2.281c0.281-0.281 0.438-0.656 0.438-1.047 0-0.406-0.156-0.781-0.438-1.062l-4.281-4.297 0.281-3.734c0.313 0.187 0.609 0.391 0.875 0.656l5.25 5.25c0.844 0.859 1.313 1.984 1.313 3.187zM16.109 7.688l-3.734 0.281-4.266-4.281c-0.281-0.281-0.656-0.438-1.062-0.438s-0.781 0.156-1.062 0.422l-2.297 2.281c-0.281 0.281-0.438 0.656-0.438 1.047 0 0.406 0.156 0.781 0.438 1.062l4.281 4.281-0.281 3.75c-0.313-0.187-0.609-0.391-0.875-0.656l-5.25-5.25c-0.844-0.859-1.313-1.984-1.313-3.187s0.469-2.328 1.328-3.172l2.297-2.281c0.844-0.844 1.969-1.297 3.172-1.297s2.344 0.469 3.187 1.328l5.219 5.234c0.266 0.266 0.469 0.562 0.656 0.875zM26 9c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM17.5 0.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM23.859 2.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719z" />
    `,
  },
  redo: {
    viewBox: '0 0 28 28',
    content: `
      <path fill="currentColor" d="M28 10c0 0.266-0.109 0.516-0.297 0.703l-8 8c-0.187 0.187-0.438 0.297-0.703 0.297-0.547 0-1-0.453-1-1v-4h-3.5c-6.734 0-11.156 1.297-11.156 8.75 0 0.641 0.031 1.281 0.078 1.922 0.016 0.25 0.078 0.531 0.078 0.781 0 0.297-0.187 0.547-0.5 0.547-0.219 0-0.328-0.109-0.438-0.266-0.234-0.328-0.406-0.828-0.578-1.188-0.891-2-1.984-4.859-1.984-7.047 0-1.75 0.172-3.547 0.828-5.203 2.172-5.391 8.547-6.297 13.672-6.297h3.5v-4c0-0.547 0.453-1 1-1 0.266 0 0.516 0.109 0.703 0.297l8 8c0.187 0.187 0.297 0.438 0.297 0.703z" />
    `,
  },
  undo: {
    viewBox: '0 0 28 28',
    content: `
      <path fill="currentColor" d="M28 17.5c0 2.188-1.094 5.047-1.984 7.047-0.172 0.359-0.344 0.859-0.578 1.188-0.109 0.156-0.219 0.266-0.438 0.266-0.313 0-0.5-0.25-0.5-0.547 0-0.25 0.063-0.531 0.078-0.781 0.047-0.641 0.078-1.281 0.078-1.922 0-7.453-4.422-8.75-11.156-8.75h-3.5v4c0 0.547-0.453 1-1 1-0.266 0-0.516-0.109-0.703-0.297l-8-8c-0.187-0.187-0.297-0.438-0.297-0.703s0.109-0.516 0.297-0.703l8-8c0.187-0.187 0.438-0.297 0.703-0.297 0.547 0 1 0.453 1 1v4h3.5c5.125 0 11.5 0.906 13.672 6.297 0.656 1.656 0.828 3.453 0.828 5.203z" />
    `,
  },
  arrowRight: {
    viewBox: '0 0 32 32',
    content: `
      <path fill="currentColor" d="M31 16l-15-15v9h-16v12h16v9z"/>
    `,
  },
  arrowDouble: {
    viewBox: '0 0 46 30',
    content: `
      <path fill="currentColor" d="M31 0L46 15L31 30V21H15V30L0 15L15 0V9H31V0Z" />
    `,
  },
  plus: {
    viewBox: '0 0 22 28',
    content: `
      <path fill="currentColor" d="M22 11.5v3c0 0.828-0.672 1.5-1.5 1.5h-6.5v6.5c0 0.828-0.672 1.5-1.5 1.5h-3c-0.828 0-1.5-0.672-1.5-1.5v-6.5h-6.5c-0.828 0-1.5-0.672-1.5-1.5v-3c0-0.828 0.672-1.5 1.5-1.5h6.5v-6.5c0-0.828 0.672-1.5 1.5-1.5h3c0.828 0 1.5 0.672 1.5 1.5v6.5h6.5c0.828 0 1.5 0.672 1.5 1.5z" />
    `,
  },
  export: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M15.984 5.016l-1.406 1.406-1.594-1.594v11.156h-1.969v-11.156l-1.594 1.594-1.406-1.406 3.984-4.031zM20.016 9.984v11.016q0 0.844-0.586 1.43t-1.43 0.586h-12q-0.844 0-1.43-0.586t-0.586-1.43v-11.016q0-0.797 0.586-1.383t1.43-0.586h3v1.969h-3v11.016h12v-11.016h-3v-1.969h3q0.844 0 1.43 0.586t0.586 1.383z" />
    `,
  },
  newUpdates: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M12.984 12.984v-6h-1.969v6h1.969zM12.984 17.016v-2.016h-1.969v2.016h1.969zM23.016 12l-2.438 2.766 0.328 3.703-3.609 0.797-1.875 3.188-3.422-1.453-3.422 1.453-1.875-3.141-3.609-0.844 0.328-3.703-2.438-2.766 2.438-2.813-0.328-3.656 3.609-0.797 1.875-3.188 3.422 1.453 3.422-1.453 1.875 3.188 3.609 0.797-0.328 3.703z" />
    `,
  },
  verified: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z" />
    `,
  },
  grab: {
    viewBox: '0 0 26 24',
    content: `
      <path fill="currentColor" stroke="black" d="M13 0.545456C11.6133 0.545456 10.5 1.67898 10.5 3.09091V5.63636H9.875V3.78693C9.875 2.53409 8.9375 1.5 7.6875 1.5C6.47656 1.5 5.5 2.49432 5.5 3.72727V12.2585L4.875 11.6619V8.24148C4.875 6.98864 3.9375 5.95455 2.6875 5.95455C1.47656 5.95455 0.5 6.94886 0.5 8.18182V12.6364C0.5 13.2528 0.753906 13.8295 1.18359 14.267L7.23828 20.1534C7.80469 20.7301 8 21.3864 8 22.1818C8 22.8778 8.56641 23.4545 9.25 23.4545H21.75C22.4336 23.4545 23 22.8778 23 22.1818V21.6847C23 21.1676 23.0586 20.6506 23.1953 20.1534L25.3047 11.483C25.4414 10.9858 25.5 10.4687 25.5 9.9517V5.05966C25.5 3.80682 24.5625 2.77273 23.3125 2.77273C22.1016 2.77273 21.125 3.76705 21.125 5V5.63636H20.5V3.15057C20.5 2.09659 19.7969 1.12216 18.7617 0.903411C18.625 0.883524 18.4688 0.863638 18.3125 0.863638C17.1016 0.863638 16.125 1.85796 16.125 3.09091V5.63636H15.5V3.21023C15.5 1.87784 14.5625 0.704547 13.2539 0.565342C13.1563 0.545456 13.0781 0.545456 13 0.545456Z" />
    `,
  },
  circlePlus: {
    viewBox: '0 0 24 24',
    content: `
      <path fill="currentColor" d="M17.016 12.984v-1.969h-4.031v-4.031h-1.969v4.031h-4.031v1.969h4.031v4.031h1.969v-4.031h4.031zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z" />
    `,
  },
  cation: {
    viewBox: '0 0 24 24',
    content: `
      <g>
        <circle stroke="currentColor" fill="transparent" cx="12" cy="12" r="10" />
        <line stroke="currentColor" x1="7" y1="12" x2="17" y2="12" />
        <line stroke="currentColor" x1="12" y1="7" x2="12" y2="17" />
      </g>
    `,
  },
  anion: {
    viewBox: '0 0 24 24',
    content: `
      <g>
        <circle stroke="currentColor" fill="transparent" cx="12" cy="12" r="10" />
        <line stroke="currentColor" x1="7" y1="12" x2="17" y2="12" />
      </g>
    `,
  },
  neutral: {
    viewBox: '0 0 24 24',
    content: `
      <circle stroke="currentColor" fill="transparent" cx="12" cy="12" r="10" />
    `,
  },
  error: {
    viewBox: '0 0 24 28',
    content: `
     <path fill="currentColor" d="M17.953 17.531c0-0.266-0.109-0.516-0.297-0.703l-2.828-2.828 2.828-2.828c0.187-0.187 0.297-0.438 0.297-0.703s-0.109-0.531-0.297-0.719l-1.406-1.406c-0.187-0.187-0.453-0.297-0.719-0.297s-0.516 0.109-0.703 0.297l-2.828 2.828-2.828-2.828c-0.187-0.187-0.438-0.297-0.703-0.297s-0.531 0.109-0.719 0.297l-1.406 1.406c-0.187 0.187-0.297 0.453-0.297 0.719s0.109 0.516 0.297 0.703l2.828 2.828-2.828 2.828c-0.187 0.187-0.297 0.438-0.297 0.703s0.109 0.531 0.297 0.719l1.406 1.406c0.187 0.187 0.453 0.297 0.719 0.297s0.516-0.109 0.703-0.297l2.828-2.828 2.828 2.828c0.187 0.187 0.438 0.297 0.703 0.297s0.531-0.109 0.719-0.297l1.406-1.406c0.187-0.187 0.297-0.453 0.297-0.719zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z" />
    `,
  },
  warning: {
    viewBox: '0 0 28 28',
    content: `
    <path fill="currentColor" d="M16 21.484v-2.969c0-0.281-0.219-0.516-0.5-0.516h-3c-0.281 0-0.5 0.234-0.5 0.516v2.969c0 0.281 0.219 0.516 0.5 0.516h3c0.281 0 0.5-0.234 0.5-0.516zM15.969 15.641l0.281-7.172c0-0.094-0.047-0.219-0.156-0.297-0.094-0.078-0.234-0.172-0.375-0.172h-3.437c-0.141 0-0.281 0.094-0.375 0.172-0.109 0.078-0.156 0.234-0.156 0.328l0.266 7.141c0 0.203 0.234 0.359 0.531 0.359h2.891c0.281 0 0.516-0.156 0.531-0.359zM15.75 1.047l12 22c0.344 0.609 0.328 1.359-0.031 1.969s-1.016 0.984-1.719 0.984h-24c-0.703 0-1.359-0.375-1.719-0.984s-0.375-1.359-0.031-1.969l12-22c0.344-0.641 1.016-1.047 1.75-1.047s1.406 0.406 1.75 1.047z" />
    `,
  },
  info: {
    viewBox: '0 0 24 28',
    content: `
     <path fill="currentColor" d="M16 21.5v-2.5c0-0.281-0.219-0.5-0.5-0.5h-1.5v-8c0-0.281-0.219-0.5-0.5-0.5h-5c-0.281 0-0.5 0.219-0.5 0.5v2.5c0 0.281 0.219 0.5 0.5 0.5h1.5v5h-1.5c-0.281 0-0.5 0.219-0.5 0.5v2.5c0 0.281 0.219 0.5 0.5 0.5h7c0.281 0 0.5-0.219 0.5-0.5zM14 7.5v-2.5c0-0.281-0.219-0.5-0.5-0.5h-3c-0.281 0-0.5 0.219-0.5 0.5v2.5c0 0.281 0.219 0.5 0.5 0.5h3c0.281 0 0.5-0.219 0.5-0.5zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z" />
    `,
  },
  success: {
    viewBox: '0 0 24 28',
    content: `
     <path fill="currentColor" d="M20.062 11.469c0-0.266-0.094-0.531-0.281-0.719l-1.422-1.406c-0.187-0.187-0.438-0.297-0.703-0.297s-0.516 0.109-0.703 0.297l-6.375 6.359-3.531-3.531c-0.187-0.187-0.438-0.297-0.703-0.297s-0.516 0.109-0.703 0.297l-1.422 1.406c-0.187 0.187-0.281 0.453-0.281 0.719s0.094 0.516 0.281 0.703l5.656 5.656c0.187 0.187 0.453 0.297 0.703 0.297 0.266 0 0.531-0.109 0.719-0.297l8.484-8.484c0.187-0.187 0.281-0.438 0.281-0.703zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z" />
    `,
  },
};

export type IconId = keyof typeof iconDefinitions | ToolkitIconId;
