import { action, computed, makeObservable, observable, toJS } from 'mobx';

import {
  calculateAllElectrons,
  calculateFreeElectrons,
  canModifyCharge,
  clamp,
  getTargetCoords,
  isElectron,
} from '../utils';
import { IBoardStore } from './types';
import {
  Coords,
  ELECTRON_DEFINITION,
  IAtom,
  IBoardPiece,
  IGamePiece,
  IShortBoardPiece,
} from './domain';
import { MAX_ELECTRONS_NUMBER } from '../components/Board/consts';
import { strings } from '../strings';

export class BoardStore implements IBoardStore {
  boardVersionId = 0;

  width = 0;
  height = 0;

  board: IBoardPiece[][] = [];

  constructor(width: number, height: number) {
    this.resize(width, height);

    makeObservable(this, {
      boardVersionId: observable,
      board: observable,
      boardPieces: computed,
      width: observable,
      height: observable,
      reset: action,

      putAtom: action.bound,
      moveAtom: action.bound,
      removeAtom: action.bound,
      addBond: action.bound,

      highlightPossibleBonds: action.bound,
      clearHighlight: action.bound,

      replaceBoardContent: action,
      incrementBoardVersion: action.bound,

      detachElectron: action,
    });
  }

  incrementBoardVersion() {
    if (this.boardVersionId > 99999999) {
      this.boardVersionId = 0;
      return;
    }
    this.boardVersionId++;
  }

  reset() {
    this.boardPieces.forEach((bp) => (bp.piece = undefined));
    this.clearHighlight();
    this.incrementBoardVersion();
  }

  getAtomCount() {
    return this.boardPieces.reduce<Record<string, number>>(
      (acc, bp) =>
        bp.piece
          ? {
              ...acc,
              [bp.piece.atom.symbol]: (acc[bp.piece.atom.symbol] || 0) + 1,
            }
          : acc,
      {}
    );
  }

  private checkCoords(coords: Coords): boolean {
    return (
      clamp(coords[0], 0, this.width - 1) === coords[0] &&
      clamp(coords[1], 0, this.height - 1) === coords[1]
    );
  }

  resize(width: number, height: number) {
    this.board = [];

    this.width = width;
    this.height = height;

    for (let y = 0; y < height; y++) {
      this.board[y] = [];
      for (let x = 0; x < width; x++) {
        const newBoardPiece: IBoardPiece = {
          coords: [x, y],
          isHighlighted: false,
        };
        this.board[y].push(newBoardPiece);
      }
    }
  }

  private getBoardPiece(x: number, y: number): IBoardPiece | null {
    if (!this.checkCoords([x, y])) return null;

    return this.board[y][x];
  }

  // TODO: Remove this and switch to iterating through board arrays.
  //       This gets recalculated way too often.
  get boardPieces() {
    const result: IBoardPiece[] = [];

    for (const col of this.board) {
      for (const piece of col) {
        result.push(piece);
      }
    }

    return result;
  }

  getBoardPieceByCoords(coords: Coords): IBoardPiece | null {
    const [x, y] = coords;
    return this.getBoardPiece(x, y);
  }

  private getAdjacentBoardPieces(coords: Coords): IBoardPiece[] {
    if (!this.checkCoords(coords)) {
      throw new Error('getAdjacentBoardPieces - coordinates out of bounds');
    }

    const [x, y] = coords;

    return [
      this.getBoardPiece(x, y - 1),
      this.getBoardPiece(x + 1, y),
      this.getBoardPiece(x, y + 1),
      this.getBoardPiece(x - 1, y),
    ].filter(Boolean) as IBoardPiece[];
  }

  getAdjacentGamePiece(piece: IGamePiece, direction: number) {
    const coords = piece.boardPiece?.coords;
    if (!coords) {
      throw new Error(
        'getAdjacentGamePiece - game piece has no board piece defined'
      );
    }

    const targetCoords = getTargetCoords(coords, direction);
    const targetGamePiece = this.getBoardPieceByCoords(targetCoords);
    return targetGamePiece?.piece || null;
  }

  putAtom(coords: Coords, atom: IAtom, charge = 0): IBoardPiece {
    if (!this.checkCoords(coords)) {
      throw new Error('putAtom - coordinates out of bounds');
    }

    const [x, y] = coords;

    if (this.board[y][x] && this.board[y][x]?.piece) {
      throw new Error('putAtom - board piece is already occupied');
    }

    this.board[y][x].piece = {
      atom,
      isSelected: false,
      isLocked: false,
      bonds: [0, 0, 0, 0],
      charge,
      selectedBondIndex: null,
    };

    const gamePiece = this.board[y][x].piece;

    if (gamePiece !== undefined) {
      gamePiece.boardPiece = this.board[y][x];
    }

    this.incrementBoardVersion();

    return this.board[y][x];
  }

  moveAtom(origin: Coords, destination: Coords) {
    const gamePiece = this.getBoardPieceByCoords(origin)?.piece;

    if (!gamePiece) {
      throw new Error(`moveAtom - no atom to move`);
    }

    const destinationPiece = this.getBoardPieceByCoords(destination);

    if (!destinationPiece) {
      throw new Error(`moveAtom - destination out of bounds`);
    }

    if (destinationPiece.piece) {
      throw new Error(`moveAtom - destination already occupied`);
    }

    this.removeAtom(origin);
    this.putAtom(destination, gamePiece.atom, gamePiece.charge);
  }

  removeAtom(coords: Coords): void {
    if (!this.checkCoords(coords)) {
      throw new Error('removeAtom - coordinates out of bounds');
    }

    const [x, y] = coords;

    if (!this.board[y][x].piece) {
      throw new Error('removeAtom - nothing to remove');
    }

    const boardPiece = this.board[y][x];

    if (boardPiece) {
      this.removeBonds(boardPiece);
      boardPiece.piece = undefined;
      this.clearHighlight();
      this.incrementBoardVersion();
    }
  }

  addBond(boardPiece1: IBoardPiece, boardPiece2: IBoardPiece): void {
    const coords1 = boardPiece1.coords;
    const coords2 = boardPiece2.coords;

    if (!this.checkCoords(coords1) || !this.checkCoords(coords2)) {
      throw new Error('addBond - coordinates out of bounds');
    }

    const gamePiece1 = boardPiece1.piece;
    const gamePiece2 = boardPiece2.piece;

    if (!gamePiece1 || !gamePiece2) {
      throw new Error('addBond - no atom(s) to bind');
    }

    if (gamePiece1 === gamePiece2) {
      throw new Error('addBond - cannot bind an atom to itself');
    }

    const [x1, y1] = coords1;
    const [x2, y2] = coords2;

    if (Math.abs(x1 - x2) + Math.abs(y1 - y2) > 1) {
      throw new Error('addBond - atoms are too far apart');
    }

    if (
      !calculateFreeElectrons(gamePiece1) ||
      !calculateFreeElectrons(gamePiece2)
    ) {
      throw new Error(strings.errors.addBondNoFreeElectrons);
    }

    if (!gamePiece1.bonds) gamePiece1.bonds = [0, 0, 0, 0];
    if (!gamePiece2.bonds) gamePiece2.bonds = [0, 0, 0, 0];

    if (
      calculateAllElectrons(gamePiece1) >= MAX_ELECTRONS_NUMBER ||
      calculateAllElectrons(gamePiece2) >= MAX_ELECTRONS_NUMBER
    ) {
      throw new Error(
        strings.errors.addBondUpperLimitReached(MAX_ELECTRONS_NUMBER)
      );
    }

    if (x1 < x2) {
      gamePiece1.bonds[1] += 1;
      gamePiece2.bonds[3] += 1;
    }

    if (x1 > x2) {
      gamePiece1.bonds[3] += 1;
      gamePiece2.bonds[1] += 1;
    }

    if (y1 < y2) {
      gamePiece1.bonds[2] += 1;
      gamePiece2.bonds[0] += 1;
    }

    if (y1 > y2) {
      gamePiece1.bonds[0] += 1;
      gamePiece2.bonds[2] += 1;
    }

    this.incrementBoardVersion();
  }

  removeBonds(boardPiece: IBoardPiece): void {
    const gamePiece = boardPiece.piece;

    if (!gamePiece) {
      throw new Error('removeBonds - no atom selected');
    }

    gamePiece.bonds?.forEach((count, direction) => {
      const adjacentGamePiece = this.getAdjacentGamePiece(gamePiece, direction);
      if (adjacentGamePiece?.bonds) {
        adjacentGamePiece.bonds[(direction + 2) % 4] = 0;
      }
    });

    gamePiece.bonds = [0, 0, 0, 0];

    this.incrementBoardVersion();
  }

  highlightPossibleBonds(coords: Coords): void {
    if (!this.getBoardPieceByCoords(coords)) {
      throw new Error('highlightPossibleBonds - no atom at selected location');
    }

    const adjacentBoardPieces = this.getAdjacentBoardPieces(coords);

    this.clearHighlight();

    adjacentBoardPieces.forEach((piece) => {
      if (piece.piece && !isElectron(piece.piece)) {
        piece.isHighlighted = true;
      }
    });
  }

  clearHighlight(): void {
    for (let y = 0; y < this.height; y++) {
      for (let x = 0; x < this.width; x++) {
        this.board[y][x].isHighlighted = false;
      }
    }
  }

  replaceBoardContent(newPieces: IShortBoardPiece[]) {
    for (let y = 0; y < this.height; y++) {
      for (let x = 0; x < this.width; x++) {
        this.board[y][x].piece = undefined;
        this.board[y][x].isHighlighted = false;
      }
    }

    for (const newPiece of newPieces) {
      const boardPieceByHistoryCoords = this.getBoardPieceByCoords(
        newPiece.coords
      );

      if (boardPieceByHistoryCoords) {
        boardPieceByHistoryCoords.piece = {
          atom: newPiece.atom,
          isSelected: false,
          isLocked: false,
          bonds: newPiece.bonds,
          charge: newPiece.charge,
          selectedBondIndex: null,
          boardPiece: boardPieceByHistoryCoords,
        };
      }
    }
  }

  getMolecules(): IGamePiece[][] {
    let lastMoleculeId = 0;

    this.boardPieces.forEach((bp) => {
      if (bp.piece) {
        bp.piece.moleculeId = undefined;
      }
    });

    const traverseMolecule = (piece: IGamePiece | null, newId: number) => {
      if (!piece) return;

      piece.moleculeId = newId;

      if (!piece.bonds) return;

      piece.bonds.forEach((bondCount, direction) => {
        if (bondCount) {
          const nextPiece = this.getAdjacentGamePiece(piece, direction);
          if (!nextPiece?.moleculeId) {
            traverseMolecule(nextPiece, newId);
          }
        }
      });
    };

    for (let y = 0; y < this.height; y++) {
      for (let x = 0; x < this.width; x++) {
        const piece = this.board[y][x].piece;
        if (!piece || piece.moleculeId) {
          continue;
        }
        traverseMolecule(piece, ++lastMoleculeId);
      }
    }

    const result: IGamePiece[][] = [];

    this.boardPieces.forEach((bp) => {
      const gamePiece = bp.piece;
      if (!gamePiece) {
        return;
      }

      const moleculeId = bp.piece?.moleculeId;
      if (!moleculeId) return;
      const resultIndex = moleculeId - 1;
      if (!result[resultIndex]) {
        result[resultIndex] = [];
      }
      result[resultIndex].push(toJS(gamePiece));
    });

    return result;
  }

  private getAdjacentSpot(coords: Coords): Coords | null {
    const [x, y] = coords;
    for (let yy = y - 1; yy <= y + 1; yy++) {
      for (let xx = x - 1; xx <= x + 1; xx++) {
        if (xx === x && yy === y) {
          continue;
        }

        if (!this.checkCoords([xx, yy])) {
          continue;
        }

        if (!this.board[yy][xx].piece) {
          return [xx, yy];
        }
      }
    }

    return null;
  }

  detachElectron(piece: IGamePiece) {
    if (!piece.boardPiece?.coords) {
      return;
    }

    const coords = this.getAdjacentSpot(piece.boardPiece?.coords);

    const { detachElectronLimitReached, detachElectronNoRoom } = strings.errors;

    if (!coords) {
      throw new Error(detachElectronNoRoom);
    }

    if (isElectron(piece)) {
      return;
    }

    if (!canModifyCharge(piece, -1)) {
      throw new Error(detachElectronLimitReached);
    }

    piece.charge--;

    this.putAtom(coords, ELECTRON_DEFINITION);
  }
}
