import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { GamePiece } from '../GamePiece/GamePiece';
import { LR } from '../../stores/domain';
import { useStores } from '../../providers/store/use-stores';
import { ControlScheme } from '../../stores/types';
import { KeyboardHint } from '../Keyboard/KeyboardHint';
import { isTouchDevice, stopSpecialPropagation } from '../../utils';
import { ChargeSelector } from './ChargeSelector';

export const Bank: FC = observer(() => {
  const {
    appStore: { electronDistributionStrategy },
    bankStore: {
      setSelectedBankPieceCharge,
      setSelectedBankPiece,
      selectedBankPiece,
      gamePieces,
      charge,
      setCharge,
      bankControlState,
    },
    simulationStore: { activeTab },
    inputStore: { activeControlScheme },
  } = useStores();

  useEffect(() => {
    if (selectedBankPiece) {
      setSelectedBankPieceCharge(charge);
    }
  }, [charge, selectedBankPiece, setSelectedBankPieceCharge]);

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === 'Shift') {
        setCharge(1);
        return;
      }

      if (key === 'Alt' || key === 'Meta') {
        setCharge(-1);
      }
    };

    const handleKeyUp = ({ key }: KeyboardEvent) => {
      if (key === 'Shift' || key === 'Meta' || key === 'Alt') {
        setCharge(0);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBankEnabled = activeTab === LR.left && bankControlState.isEnabled;

  return (
    <div className="w-36 shadow-outer bg-platinum relative flex flex-col items-stretch justify-start z-10">
      <div className="bg-platinum-light py-5 flex flex-col items-center justify-center text-lg text-blue font-bold shadow-sm">
        Bank
      </div>
      <div className="overflow-y-auto">
        <div
          className={classNames(
            'grow flex flex-col items-center justify-start m-sm gap-sm',
            !isBankEnabled && 'opacity-50'
          )}
          onClick={() => setSelectedBankPiece(null)}
        >
          {gamePieces.map((piece, index) => (
            <button
              className={classNames(
                'relative overflow-hidden rounded-lg flex bg-platinum-light w-full h-[91px] flex-col items-center focus:outline-none focus:ring-1 focus:ring-blue-neutral',
                piece.isLocked && 'opacity-50',
                selectedBankPiece?.atom.symbol === piece.atom.symbol &&
                  'bg-white'
              )}
              disabled={!isBankEnabled}
              key={piece.atom.name}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedBankPiece(piece);
              }}
              onKeyDown={stopSpecialPropagation}
            >
              <p
                className={classNames(
                  'inline-block flex-0 w-full text-md text-center bg-gray-10',
                  selectedBankPiece?.atom.symbol === piece.atom.symbol &&
                    'bg-blue-neutral text-white'
                )}
              >
                {piece.atom.name}
              </p>
              <div className="relative flex-1 flex justify-center items-center">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  cursor="pointer"
                >
                  <GamePiece
                    data={piece}
                    onClick={setSelectedBankPiece}
                    eds={electronDistributionStrategy}
                  />
                </svg>
              </div>
              {activeControlScheme === ControlScheme.keyboard && (
                <KeyboardHint className="absolute bottom-xs right-xs">
                  {index + 1}
                </KeyboardHint>
              )}
            </button>
          ))}
        </div>
        {isTouchDevice() && (
          <ChargeSelector charge={charge} onChange={setCharge} />
        )}
      </div>
    </div>
  );
});
