import { action, makeObservable, observable } from 'mobx';
import { ICAPI, NotificationType } from 'asu-sim-toolkit';

import { SimMode, ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore } from './capi-bound-store';
import { ControlStore, IControlState } from './control-store';
import {
  ElectronDistributionStrategy,
  simModeDefinitions,
  Zoom,
} from './domain';
import { help } from './modals';

const recursiveAssign = <T>(target: T, source: Partial<T> | null) => {
  Object.entries(source || {}).forEach(([key, value]) => {
    typeof value === 'object'
      ? recursiveAssign((target as any)[key], value)
      : ((target as any)[key] = value);
  });
};

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  fullscreenControlState: IControlState;

  zoom = 1;
  isFullscreen = false;
  fullscreenElement: Element | null = null;

  electronDistributionStrategy: ElectronDistributionStrategy =
    ElectronDistributionStrategy.prioritisePairs;

  simMode: SimMode = SimMode.all;
  errorBarEnabled = true;
  deleteButtonEnabled = true;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    this.fullscreenControlState = new ControlStore(
      capi,
      'Sim.Fullscreen.Enabled',
      'Sim.Fullscreen.Visible'
    );

    makeObservable(this, {
      zoom: observable,
      zoomIn: action.bound,
      zoomOut: action.bound,

      simMode: observable,
      setMode: action.bound,

      isFullscreen: observable,
      toggleFullscreen: action.bound,
      registerFullscreenElement: action.bound,
      setFullscreen: action.bound,

      showHelpModal: action.bound,
      electronDistributionStrategy: observable,
      errorBarEnabled: observable,
      deleteButtonEnabled: observable,
    });

    this.synchronizeFromCapi('simMode', 'Sim.Mode');

    this.synchronizeFromCapi('errorBarEnabled', 'Sim.Error.Enabled');
    this.synchronizeFromCapi('deleteButtonEnabled', 'Sim.Delete.Enabled');

    this.synchronizeToCapi('isFullscreen', 'Sim.Fullscreen.Set');
    this.onCapi('Sim.Fullscreen.Set', this.setFullscreen);

    this.synchronizeFromCapi(
      'electronDistributionStrategy',
      'Sim.ElectronNotation'
    );

    document.addEventListener('fullscreenchange', () =>
      this.setFullscreen(Boolean(document.fullscreenElement))
    );
  }

  zoomIn() {
    if (this.zoom <= 0.5) return;

    this.zoom = Number((this.zoom - 0.25).toFixed(2));
    this.rootStore.notificationStore.addNotification(
      `Current zoom: ${Zoom[this.zoom as keyof typeof Zoom]}`,
      {
        key: 'zoom',
        type: NotificationType.info,
      }
    );
  }

  zoomOut() {
    if (this.zoom >= 1.5) return;

    this.zoom = Number((this.zoom + 0.25).toFixed(2));
    this.rootStore.notificationStore.addNotification(
      `Current zoom: ${Zoom[this.zoom as keyof typeof Zoom]}`,
      {
        key: 'zoom',
        type: NotificationType.info,
      }
    );
  }

  init() {
    this.onCapi('Sim.Mode', this.setMode);
  }

  registerFullscreenElement(element: Element) {
    this.fullscreenElement = element;
  }

  setMode(mode: SimMode) {
    const { simulationStore, bankStore } = this.rootStore;

    if (!mode || !simulationStore || !bankStore) return;

    this.simMode = mode;
    const def = mode !== SimMode.custom ? simModeDefinitions[mode] : null;

    if (def) {
      def.SimStore && recursiveAssign(simulationStore, def.SimStore);
      def.BankStore && recursiveAssign(bankStore, def.BankStore);
      def.AppStore && recursiveAssign(this, def.AppStore);
    }
  }

  setFullscreen(newValue: boolean) {
    if (newValue !== Boolean(document.fullscreenElement)) {
      newValue
        ? this.fullscreenElement?.requestFullscreen()
        : document.exitFullscreen();
    }
    this.isFullscreen = newValue;
  }

  toggleFullscreen() {
    this.setFullscreen(!this.isFullscreen);
  }

  showError = (text: string) => {
    this.rootStore.notificationStore.addNotification(text, {
      type: NotificationType.error,
    });
  };

  // TODO replace lorem ipsum with real copy
  async showHelpModal() {
    await help(
      this.rootStore.modalStore,
      'Help Modal',
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.'
    );
  }
}
