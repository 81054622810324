import {
  CAPI,
  IModalStore,
  INotificationStore,
  IRecapStore,
  ISimContextStore,
  ModalStore,
  NotificationStore,
  RecapStore,
  SimContextStore,
} from 'asu-sim-toolkit';
import { ICapiModel, capiSchema } from '../capi';
import { isDebugMode } from '../config';
import {
  IAppStore,
  IBankStore,
  IInputStore,
  IRootStore,
  ISimulationStore,
} from './types';
import { AppStore } from './app-store';
import { SimulationStore } from './simulation-store';
import { BankStore } from './bank-store';
import { InputStore } from './input-store';
import { versionHistory } from '../version-history';

export class RootStore implements IRootStore {
  appStore: IAppStore;
  simulationStore: ISimulationStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  bankStore: IBankStore;
  recapStore: IRecapStore;
  inputStore: IInputStore;
  simContextStore: ISimContextStore;

  constructor(capi: CAPI<ICapiModel>) {
    capi.init(capiSchema);

    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }

    this.appStore = new AppStore(this, capi);
    this.simulationStore = new SimulationStore(this, capi);
    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.bankStore = new BankStore(this, capi);
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.inputStore = new InputStore(this);
    this.simContextStore = new SimContextStore(capi);
  }
}
