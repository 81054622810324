import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../providers/store/use-stores';
import { GamePiece } from '../GamePiece/GamePiece';
import { isTouchDevice } from '../../utils';
import { BOARD_PIECE_SIZE } from '../Board/consts';
import { Icon } from '../Icon';

export interface IMousePosition {
  x: number;
  y: number;
}

export const CursorIndicator = observer(() => {
  const [pointerPosition, setPointerPosition] = useState<IMousePosition>({
    x: 0,
    y: 0,
  });
  const [isCursorHidden, hideCursor] = useState<boolean>(false);
  const {
    appStore: { electronDistributionStrategy, zoom },
    simulationStore: { draggedGamePiece },
  } = useStores();

  const updateCursorVisibility = (event: PointerEvent | MouseEvent) => {
    if (
      (event.target as HTMLElement).tagName !== 'rect' ||
      (event.target as HTMLElement).dataset.occupied === 'true'
    ) {
      hideCursor(true);
    } else {
      hideCursor(false);
    }
  };

  useEffect(() => {
    const pointerMoveHandler = (event: PointerEvent) => {
      const { clientX, clientY } = event;
      setPointerPosition({ x: clientX, y: clientY });
      updateCursorVisibility(event);
    };

    const pointerClickHandler = (event: MouseEvent) =>
      updateCursorVisibility(event);

    document.addEventListener('pointermove', pointerMoveHandler);
    document.addEventListener('click', pointerClickHandler);
    return () => {
      document.removeEventListener('pointermove', pointerMoveHandler);
      document.removeEventListener('click', pointerClickHandler);
    };
  }, [isCursorHidden]);

  if ((isCursorHidden && !draggedGamePiece) || !draggedGamePiece?.boardPiece)
    return null;

  return (
    <div
      className="fixed pointer-events-none select-none"
      style={{ top: pointerPosition.y, left: pointerPosition.x }}
    >
      <svg
        width={BOARD_PIECE_SIZE / zoom}
        height={BOARD_PIECE_SIZE / zoom}
        viewBox={`0 0 ${BOARD_PIECE_SIZE} ${BOARD_PIECE_SIZE}`}
        className="select-none absolute"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <GamePiece
          data={draggedGamePiece}
          eds={electronDistributionStrategy}
          isDragged
        />
      </svg>
      {!isTouchDevice() && (
        <Icon
          id="grab"
          className="text-white text-[16px] absolute"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  );
});
