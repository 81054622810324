import { ControlScheme, InputCommand, InputMap } from './types';

export const keyboardInputMap: InputMap = {
  arrowup: InputCommand.Up,
  arrowdown: InputCommand.Down,
  arrowleft: InputCommand.Left,
  arrowright: InputCommand.Right,
  w: InputCommand.Up,
  s: InputCommand.Down,
  a: InputCommand.Left,
  d: InputCommand.Right,
  escape: InputCommand.Cancel,
  enter: InputCommand.Select,
  ' ': InputCommand.Select,
  shift_enter: InputCommand.Select,
  'shift_ ': InputCommand.Select,
  alt_enter: InputCommand.Select,
  'alt_ ': InputCommand.Select,
  '1': InputCommand.BankItem_1,
  '2': InputCommand.BankItem_2,
  '3': InputCommand.BankItem_3,
  '4': InputCommand.BankItem_4,
  '5': InputCommand.BankItem_5,
  '6': InputCommand.BankItem_6,
  '7': InputCommand.BankItem_7,
  '8': InputCommand.BankItem_8,
  '9': InputCommand.BankItem_9,
  backspace: InputCommand.Delete,
  ctrl_z: InputCommand.Undo,
  ctrl_shift_z: InputCommand.Redo,
  cmd_z: InputCommand.Undo,
  cmd_shift_z: InputCommand.Redo,
  r: InputCommand.SwitchToRight,
  l: InputCommand.SwitchToLeft,
  b: InputCommand.SwitchToBoard,
  'shift_+': InputCommand.DetachElectron,
  '[': InputCommand.ZoomIn,
  ']': InputCommand.ZoomOut,
  i: InputCommand.ShowHelp,
};

export const mouseInputMap: InputMap = {
  leftClick: InputCommand.Select,
};

export const inputMaps: Record<ControlScheme, InputMap> = {
  [ControlScheme.keyboard]: keyboardInputMap,
  [ControlScheme.mouse]: mouseInputMap,
};
