import React, { FunctionComponent } from 'react';

interface IElectronProps {
  angle: number;
  isShadow?: boolean;
}

export const Electron: FunctionComponent<IElectronProps> = ({
  angle,
  isShadow,
}) => (
  <g
    style={{
      zIndex: 10,
      transform: `translate(25px,25px) rotate(${angle}deg)`,
      transition: 'transform .25s ease-in-out',
    }}
  >
    <circle
      cx="20"
      cy="0"
      r="2"
      fill={isShadow ? 'none' : 'black'}
      stroke={isShadow ? 'silver' : 'none'}
    />
  </g>
);
