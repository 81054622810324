import {
  IModalStore,
  INotificationStore,
  IRecapStore,
  ISimContextStore,
} from 'asu-sim-toolkit';

import {
  Coords,
  IAtom,
  IBoardPiece,
  IGamePiece,
  LR,
  IShortBoardPiece,
  Atoms,
  ElectronDistributionStrategy,
  YieldType,
} from './domain';
import { IControlState } from './control-store';
import { CapiAtom, SimMode } from '../capi';

export interface IAppStore {
  fullscreenControlState: IControlState;
  isFullscreen: boolean;

  zoom: number;
  // NOTE: Miscellaneous CAPI params.
  //       May end up somewhere else in the future.
  simMode: SimMode;
  deleteButtonEnabled: boolean;
  errorBarEnabled: boolean;
  electronDistributionStrategy: ElectronDistributionStrategy;

  zoomIn(): void;
  zoomOut(): void;
  showError(text: string): void;

  init(): void;
  setMode(mode: SimMode): void;
  registerFullscreenElement(element: Element): void;
  toggleFullscreen(): void;
  showHelpModal(): Promise<void>;
}

export interface IBoardStore {
  boardVersionId: number;

  board: IBoardPiece[][];
  boardPieces: IBoardPiece[];

  width: number;
  height: number;

  reset(): void;
  resize(width: number, height: number): void;
  getBoardPieceByCoords(coords: Coords): IBoardPiece | null;
  getAdjacentGamePiece(piece: IGamePiece, direction: number): IGamePiece | null;
  getMolecules(): IGamePiece[][];
  getAtomCount(): Record<string, number>;

  putAtom(coords: Coords, atom: IAtom, charge?: number): IBoardPiece;
  moveAtom(origin: Coords, destination: Coords): void;
  removeAtom(coords: Coords): void;

  addBond(boardPiece1: IBoardPiece, boardPiece2: IBoardPiece): void;
  removeBonds(boardPiece: IBoardPiece): void;

  replaceBoardContent(newPieces: IShortBoardPiece[]): void;
  incrementBoardVersion(): void;

  clearHighlight(): void;
  highlightPossibleBonds(coords: Coords): void;

  detachElectron(piece: IGamePiece): void;
}

export interface ISimulationStore {
  // NOTE: Used to initialize reactions between stores, after all stores
  //       are instantiated.
  init(): void;

  leftObjectiveEnabled: boolean;
  leftObjectiveVisible: boolean;
  leftObjectiveText: string;
  leftObjectiveTemplate: string;
  leftObjectiveSlots: number[];
  leftObjectiveValue: string;

  rightObjectiveEnabled: boolean;
  rightObjectiveVisible: boolean;
  rightObjectiveText: string;
  rightObjectiveTemplate: string;
  rightObjectiveSlots: number[];
  rightObjectiveValue: string;

  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
  isUnlinkDisabled: boolean;
  isTrashDisabled: boolean;

  isLeftCorrect: boolean;
  areLeftMoleculesCorrect: string;
  expectedLeftMolecules: string[];
  extraneousLeftMolecules: boolean;

  isRightCorrect: boolean;
  areRightMoleculesCorrect: string;
  expectedRightMolecules: string[];
  extraneousRightMolecules: boolean;

  attempts: number;
  boardSize: string;

  selectedGamePiece: IGamePiece | null;
  draggedGamePiece: IGamePiece | null;

  activeTab: LR;
  setActiveTab(newTab: LR): void;

  board: IBoardStore;
  atoms: Atoms;
  capiAtoms: string;

  cue: IGamePiece | null;

  yieldType: YieldType;

  setObjectiveTemplate(side: LR, newTemplate: string): void;

  setObjectiveSlot(side: LR, slotIndex: number, value: string): void;

  resizeBoard(value: string): void;
  setAtoms(capiAtoms: CapiAtom[]): void;
  deselectGamePiece(): void;
  moveAtom(piece: IGamePiece, coords: Coords): void;
  spawnSelectedAtom(coords: Coords): void;
  addElectronToAtom(targetGamePiece: IGamePiece): boolean;
  addBankElectronToAtom(targetGamePiece: IGamePiece): void;
  addBoardToHistoryStack(board: IBoardStore): void;
  activateBoardFromHistoryStack(index: number): void;
  undo(): void;
  redo(): void;
  handleBoardPieceDrag(coords: Coords): void;
  handleBoardPieceDrop(coords: Coords): void;
  setSelectedGamePiece(piece: IGamePiece | null): void;
  addBond(boardPiece1: IBoardPiece, boardPiece2: IBoardPiece): void;
  removeBonds(boardPiece: IBoardPiece): void;
  removeSelectedBonds(): void;
  detachElectron(gamePiece: IGamePiece): void;
  detachElectronFromSelectedAtom(): void;
  clear(): Promise<void>;

  exportToCAPI(): void;
}

export interface IBankStore {
  bankControlState: IControlState;
  selectedBankPiece: IGamePiece | null;
  atoms: IAtom[];
  gamePieces: IGamePiece[];
  charge: number;

  setSelectedBankPiece(bankPiece: IGamePiece | null): void;
  setSelectedBankPieceCharge(newCharge: number): void;
  setCharge(charge: number): void;
}

export enum ControlScheme {
  mouse,
  keyboard,
}

export enum InputCommand {
  Left,
  Right,
  Up,
  Down,
  Select,
  Cancel,
  NextBankItem,
  PreviousBankItem,
  BankItem_1,
  BankItem_2,
  BankItem_3,
  BankItem_4,
  BankItem_5,
  BankItem_6,
  BankItem_7,
  BankItem_8,
  BankItem_9,
  Delete,
  Undo,
  Redo,
  SwitchToLeft,
  SwitchToRight,
  SwitchToBoard,
  DetachElectron,
  ZoomIn,
  ZoomOut,
  ShowHelp,
}

export type InputMap = Record<string, InputCommand>;

export interface IInputStore {
  activeControlScheme: ControlScheme;
  cursorCoords: Coords;
  isCursorVisible: boolean;

  hideCursor(): void;
  showCursor(): void;
  moveCursorTo(coords: Coords): void;
  handleInput<T extends ControlScheme>(
    controlScheme: T,
    rawInput: string
  ): void;
  setActiveControlScheme(controlScheme: ControlScheme): void;
}

export interface IRootStore {
  appStore: IAppStore;
  simulationStore: ISimulationStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  bankStore: IBankStore;
  recapStore: IRecapStore;
  inputStore: IInputStore;
  simContextStore: ISimContextStore;
}
