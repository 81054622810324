// NOTE: These three enums are identical to their CAPI
//       counterparts... for now.
import { CapiAtom, SimMode } from '../capi';
import { IAppStore, IBankStore, ISimulationStore } from './types';

export enum LR {
  left = 'Left',
  right = 'Right',
}

export enum YieldType {
  left = 'Left',
  right = 'Right',
  both = 'Both',
  none = 'None',
}

export const Zoom = {
  0.5: '200%',
  0.75: '150%',
  1: '100%',
  1.25: '75%',
  1.5: '50%',
};

export interface IAtom {
  name: string;
  symbol: string;
  backgroundColor: string;
  borderColor: string;
  valenceElectrons: number;
  /** indicates whether the atom can form a cation */
  maxPositiveIons: number;
  /** indicates whether the atom can form an anion */
  maxNegativeIons: number;
}

export type Coords = [x: number, y: number];

export type Bonds = [top: number, right: number, bottom: number, left: number];

export enum ElectronDistributionStrategy {
  prioritiseSides = 'prioritiseSides',
  prioritisePairs = 'prioritisePairs',
}

export interface IDimensions {
  width: number;
  height: number;
}

export interface IConfig {
  leftTab: boolean;
  rightTab: boolean;
  leftText: string;
  rightText: string;

  activeTab: LR;

  boardSize: string;
  objects?: CapiAtom[];

  bank: boolean;
  fullScreen: boolean;
  errorBar: boolean;
}

export const ELECTRON_ID = '-';

export const ELECTRON_DEFINITION: IAtom = {
  name: 'Electron',
  symbol: ELECTRON_ID,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  valenceElectrons: 0,
  maxPositiveIons: 0,
  maxNegativeIons: 0,
};

export const MAX_ELECTRON_DEVIATION = 4;

export const DEFAULT_ATOMS: CapiAtom[] = [
  {
    name: 'Carbon',
    symbol: 'C',
    color: '#cce5ee',
    valenceElectrons: 4,
    maxPositiveIons: 4,
    maxNegativeIons: 4,
  },
  {
    name: 'Hydrogen',
    symbol: 'H',
    color: '#c9c0ce',
    valenceElectrons: 1,
    maxPositiveIons: 1,
    maxNegativeIons: 4,
  },
  {
    name: 'Oxygen',
    symbol: 'O',
    color: '#d1dfd5',
    valenceElectrons: 6,
    maxPositiveIons: 4,
    maxNegativeIons: 2,
  },
  {
    name: 'Nitrogen',
    symbol: 'N',
    color: '#ffe8cc',
    valenceElectrons: 5,
    maxPositiveIons: 4,
    maxNegativeIons: 3,
  },
];

interface ISimModeDefinition {
  SimStore?: Partial<ISimulationStore>;
  AppStore?: Partial<IAppStore>;
  BankStore?: Partial<IBankStore>;
}

export const simModeDefinitions: Record<
  Exclude<SimMode, SimMode.custom>,
  ISimModeDefinition
> = {
  [SimMode.all]: {
    SimStore: {
      leftObjectiveVisible: true,
      leftObjectiveText: 'Reactant',
      rightObjectiveVisible: true,
      rightObjectiveText: 'Product',
      boardSize: '20x15',
      activeTab: LR.left,
    },
    AppStore: {
      fullscreenControlState: {
        isVisible: true,
        isEnabled: true,
      },
      errorBarEnabled: true,
    },
    BankStore: {
      bankControlState: {
        isVisible: true,
        isEnabled: true,
      },
    },
  },
  [SimMode.tutorial]: {
    SimStore: {
      leftObjectiveVisible: true,
      leftObjectiveText: 'Tutorial',
      rightObjectiveVisible: false,
      rightObjectiveText: '',
      boardSize: '8x8',
      activeTab: LR.left,
    },
    AppStore: {
      fullscreenControlState: {
        isVisible: false,
        isEnabled: false,
      },
      errorBarEnabled: false,
    },
    BankStore: {
      bankControlState: {
        isVisible: true,
        isEnabled: true,
      },
    },
  },
  [SimMode.buildM]: {
    SimStore: {
      leftObjectiveVisible: true,
      leftObjectiveText: 'Objective',
      rightObjectiveVisible: false,
      rightObjectiveText: '',
      boardSize: '20x15',
      activeTab: LR.left,
    },
    AppStore: {
      fullscreenControlState: {
        isVisible: true,
        isEnabled: true,
      },
      errorBarEnabled: true,
    },
    BankStore: {
      bankControlState: {
        isVisible: true,
        isEnabled: true,
      },
    },
  },
  [SimMode.buildR]: {
    SimStore: {
      leftObjectiveVisible: true,
      leftObjectiveText: 'Reactant',
      rightObjectiveVisible: true,
      rightObjectiveText: 'Product',
      boardSize: '20x15',
      activeTab: LR.left,
    },
    AppStore: {
      fullscreenControlState: {
        isVisible: true,
        isEnabled: true,
      },
      errorBarEnabled: true,
    },
    BankStore: {
      bankControlState: {
        isVisible: true,
        isEnabled: true,
      },
    },
  },
  [SimMode.buildP]: {
    SimStore: {
      leftObjectiveVisible: true,
      leftObjectiveText: 'Reactant',
      rightObjectiveVisible: true,
      rightObjectiveText: 'Product',
      boardSize: '20x15',
      activeTab: LR.right,
    },
    AppStore: {
      fullscreenControlState: {
        isVisible: true,
        isEnabled: true,
      },
      errorBarEnabled: true,
    },
    BankStore: {
      bankControlState: {
        isVisible: true,
        isEnabled: false,
      },
    },
  },
};

export interface IGamePiece {
  atom: IAtom;
  /**
   * Number of bonds in each cardinal direction, clockwise,
   * starting at the top.
   *
   * **Example:**
   * [2,0,0,1] - double bond upwards, single bond to the left*/
  bonds?: Bonds;
  /**
   * Index of currently selected bond (null if no bond is selected).
   * Bonds are indexed clockwise, starting at the top.
   */
  selectedBondIndex: number | null;
  charge: number;
  isSelected: boolean;
  isLocked: boolean;
  boardPiece?: IBoardPiece;
  moleculeId?: number;
  isCue?: boolean;
}

// NOTE: This may hold some metadata in the future
export interface IBoardPiece {
  piece?: IGamePiece;
  coords: Coords;
  isHighlighted: boolean;
}

export interface IShortBoardPiece {
  atom: IAtom;
  charge: number;
  bonds?: Bonds;
  coords: Coords;
}

export interface IGameBoard {
  width: number;
  height: number;
  pieces: IGamePiece[]; // NOTE: list of references for easier iteration
  board: IBoardPiece[][];
}

export type Atoms = Record<string, IAtom>;
