import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { useStores } from '../../providers/store/use-stores';
import { IconButton } from '../Buttons/IconButton';
import {
  IToolbarItem,
  SEPARATOR,
  Separator,
  Toolbar,
} from '../Toolbar/Toolbar';
import { Coords, IGamePiece, LR } from '../../stores/domain';
import { useBoardPanning } from './use-board-panning';
import { useBoardSize } from './use-board-size';
import { CursorIndicator } from '../CursorIndicator/CursorIndicator';
import { BoardPieces } from './BoardPieces';
import { Bonds } from '../Bond/Bonds';
import { GamePieces } from '../GamePiece/GamePieces';
import {
  canModifyCharge,
  isElectron,
  isSafari,
  isTouchDevice,
} from '../../utils';
import { ControlScheme } from '../../stores/types';
import { keyboardInputMap } from '../../stores/input-maps';
import { getPlatformCtrlKeyLabel } from '../../utils/get-platform-ctrl-key-label';
import { setReloadHandler } from './set-reload-handler';
import { BoardCursor } from './BoardCursor';

export const Board = observer(() => {
  const {
    appStore: {
      isFullscreen,
      fullscreenControlState,
      toggleFullscreen,
      deleteButtonEnabled,
      electronDistributionStrategy,
      showHelpModal,
      zoomIn,
      zoomOut,
      zoom,
    },
    simulationStore: {
      board,
      undo,
      redo,
      isUndoDisabled,
      isRedoDisabled,
      isUnlinkDisabled,
      isTrashDisabled,
      removeSelectedBonds,
      clear,
      draggedGamePiece,
      handleBoardPieceDrag,
      handleBoardPieceDrop,
      activeTab,
      selectedGamePiece,
      detachElectronFromSelectedAtom,
    },
    inputStore,
    modalStore,
  } = useStores();
  const {
    offsets,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    center,
    setViewport,
    handleTouchMove,
    getPanningState,
    scrollToCursor,
  } = useBoardPanning(board);

  const { boardRef, dimensions } = useBoardSize((newDimensions) => {
    setViewport(newDimensions);
    center();
  });

  useEffect(() => {
    setReloadHandler(!isUndoDisabled);
  }, [isUndoDisabled]);

  useEffect(() => {
    const mouseHandler = () => {
      inputStore.setActiveControlScheme(ControlScheme.mouse);
    };

    const keyboardHandler = (ev: KeyboardEvent) => {
      if (modalStore.currentModal) {
        return;
      }

      const keyId = [
        ev.altKey && 'alt_',
        ev.metaKey && 'cmd_',
        ev.ctrlKey && 'ctrl_',
        ev.shiftKey && 'shift_',
        ev.key.toLowerCase(),
      ]
        .filter(Boolean)
        .join('');

      if (keyId === 'tab') {
        inputStore.setActiveControlScheme(ControlScheme.keyboard);
      }

      if (!Object.keys(keyboardInputMap).includes(keyId)) {
        return;
      }

      inputStore.handleInput(ControlScheme.keyboard, keyId);
    };

    document.addEventListener('mousemove', mouseHandler);
    document.addEventListener('keydown', keyboardHandler);

    return () => {
      document.removeEventListener('keydown', keyboardHandler);
      document.removeEventListener('mousemove', mouseHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardRef, inputStore]);

  const canDetachElectron =
    selectedGamePiece && canModifyCharge(selectedGamePiece, -1);

  const toolbarItems = useMemo<(IToolbarItem | Separator)[]>(
    () => [
      {
        id: 'undo',
        tip: 'Undo',
        hint: `${getPlatformCtrlKeyLabel()}+z`,
        iconId: 'undo',
        isDisabled: isUndoDisabled,
        onClick: undo,
      },
      {
        id: 'redo',
        tip: 'Redo ',
        hint: `${getPlatformCtrlKeyLabel()}+shift+z`,
        iconId: 'redo',
        isDisabled: isRedoDisabled,
        onClick: redo,
      },
      {
        id: 'unlink',
        tip: 'Unlink',
        hint: 'delete',
        iconId: 'unlink',
        onClick: removeSelectedBonds,
        isDisabled: isUnlinkDisabled,
      },
      {
        id: 'detachElectron',
        tip: 'Detach electron',
        hint: '+',
        iconId: 'circlePlus',
        onClick: detachElectronFromSelectedAtom,
        isDisabled: !canDetachElectron,
      },
      {
        id: 'trash',
        tip: 'Remove',
        hint: 'del',
        iconId: 'trash',
        isDisabled:
          isTrashDisabled || !deleteButtonEnabled || activeTab !== LR.left,
        onClick: clear,
      },
      {
        id: SEPARATOR,
      },
      {
        id: 'zoom-in',
        tip: 'Zoom in',
        hint: ' [ ',
        iconId: 'zoomIn',
        isDisabled: zoom === 0.5,
        onClick: zoomIn,
      },
      {
        id: 'zoom-out',
        tip: 'Zoom out',
        hint: ' ] ',
        iconId: 'zoomOut',
        isDisabled: zoom === 1.5,
        onClick: zoomOut,
      },
      {
        id: 'help',
        tip: 'Help',
        hint: ' i ',
        iconId: 'info',
        onClick: showHelpModal,
      },
    ],
    [
      activeTab,
      canDetachElectron,
      clear,
      deleteButtonEnabled,
      detachElectronFromSelectedAtom,
      isRedoDisabled,
      isTrashDisabled,
      isUndoDisabled,
      isUnlinkDisabled,
      redo,
      removeSelectedBonds,
      undo,
      zoom,
      zoomIn,
      zoomOut,
      showHelpModal,
    ]
  );

  const { cursorCoords, moveCursorTo, handleInput, activeControlScheme } =
    inputStore;

  const handleBoardPieceClick = useCallback(
    (coords: Coords) => {
      if (getPanningState()) return;

      moveCursorTo(coords);
      handleInput(ControlScheme.mouse, 'leftClick');
    },
    [getPanningState, handleInput, moveCursorTo]
  );

  const grid = useMemo(
    () => (
      <BoardPieces
        onBoardPieceClick={handleBoardPieceClick}
        onBoardPieceDrag={handleBoardPieceDrag}
        onBoardPieceDrop={handleBoardPieceDrop}
        height={board.height}
        width={board.width}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [board.height, board.width]
  );

  const [cursorX, cursorY] = cursorCoords;

  useEffect(() => {
    if (activeControlScheme === ControlScheme.keyboard) {
      scrollToCursor(cursorCoords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorX, cursorY, activeControlScheme]);

  const panningProps = isTouchDevice()
    ? {
        onTouchStart: handlePointerDown,
        onTouchEnd: handlePointerUp,
        onTouchMove: handleTouchMove,
      }
    : {
        onPointerDown: handlePointerDown,
        onPointerUp: handlePointerUp,
        onPointerMove: handlePointerMove,
      };

  const currentDraggedGamePiece =
    activeControlScheme === ControlScheme.keyboard
      ? selectedGamePiece &&
        (cursorX !== selectedGamePiece.boardPiece?.coords[0] ||
          cursorY !== selectedGamePiece.boardPiece?.coords[1]) &&
        (!board.board[cursorY][cursorX]?.piece ||
          (isElectron(selectedGamePiece) &&
            !isElectron(board.board[cursorY][cursorX].piece as IGamePiece)))
        ? selectedGamePiece
        : null
      : draggedGamePiece;

  return (
    <div className="relative bg-white flex-1 w-full overflow-hidden enableTouch">
      <div
        id="board"
        tabIndex={1}
        ref={boardRef}
        className={classNames(
          'absolute inset-0 outline-none focus:outline-none',
          activeControlScheme === ControlScheme.keyboard &&
            'focus:ring focus:ring-inset focus:ring-keyboardCue'
        )}
      >
        <CursorIndicator />
        <svg
          key={activeTab}
          width="100%"
          height="100%"
          viewBox={`${offsets.x},${offsets.y},${dimensions.width},${dimensions.height}`}
          onPointerLeave={() => inputStore.hideCursor()}
          {...panningProps}
        >
          <defs>
            <pattern
              id="diagonalHatch"
              width="5"
              height="5"
              patternTransform="rotate(45 0 0)"
              patternUnits="userSpaceOnUse"
            >
              <line
                x1="0"
                y1="0"
                x2="0"
                y2="5"
                strokeWidth="1"
                stroke="#40a600"
              />
            </pattern>
          </defs>
          {grid}
          <Bonds boardPieces={board.boardPieces} />
          <GamePieces
            boardPieces={board.boardPieces}
            draggedGamePiece={currentDraggedGamePiece}
            eds={electronDistributionStrategy}
          />
          {inputStore.isCursorVisible && <BoardCursor />}
        </svg>
      </div>
      <Toolbar items={toolbarItems} />
      {fullscreenControlState.isVisible && !isSafari && (
        <IconButton
          iconId={isFullscreen ? 'fullscreen_exit' : 'fullscreen'}
          className="absolute right-md bottom-md z-50"
          disabled={!fullscreenControlState.isEnabled}
          onClick={toggleFullscreen}
          id="fullscreen-button"
          label="Toggle fullscreen"
        />
      )}
    </div>
  );
});
