import { IModalStore } from 'asu-sim-toolkit';
import { ConfirmationModal } from '../components/Modals/ConfirmationModal';
import { InfoModal } from '../components/Modals/InfoModal';
import { HelpModal } from '../components/Modals/HelpModal';

export const confirm = (
  modalStore: IModalStore,
  title: string,
  message?: string,
  confirmLabel?: string,
  denyLabel?: string
) =>
  modalStore.modal(ConfirmationModal, {
    title,
    message,
    confirmLabel,
    denyLabel,
  });

export const info = (
  modalStore: IModalStore,
  title: string,
  message: string,
  confirmLabel?: string
) =>
  modalStore.modal(InfoModal, {
    title,
    message,
    confirmLabel,
  });

export const help = (modalStore: IModalStore, title: string, message: string) =>
  modalStore.modal(HelpModal, {
    title,
    message,
  });
