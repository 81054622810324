import { toJS } from 'mobx';

import { IBoardPiece, IShortBoardPiece } from '../stores/domain';

export function getShortBoardPieces(
  boardPieces: IBoardPiece[]
): IShortBoardPiece[] {
  const result: IShortBoardPiece[] = [];

  for (const boardPiece of boardPieces) {
    const { piece, coords } = boardPiece;
    if (!piece) {
      continue;
    }

    const { atom, charge, bonds } = toJS(piece);

    result.push({
      atom,
      charge,
      bonds,
      coords,
    });
  }
  return result;
}
