import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '../../providers/store/use-stores';
import { LR, YieldType } from '../../stores/domain';
import { Icon } from '../Icon';
import { ReactionForm } from '../ReactionForm/ReactionForm';
import { ObjectiveType } from './ObjectiveType';
import classNames from 'classnames';

export const Objective = observer(() => {
  const {
    simulationStore: {
      leftObjectiveEnabled,
      leftObjectiveVisible,
      leftObjectiveTemplate,
      rightObjectiveEnabled,
      rightObjectiveVisible,
      rightObjectiveTemplate,
      setObjectiveSlot,
      leftObjectiveSlots,
      rightObjectiveSlots,
      activeTab,
      yieldType,
    },
  } = useStores();

  return (
    <div className="h-[125px] text-white bg-blue relative overflow-hidden flex grow-0 flex-col items-center justify-center font-medium">
      <ObjectiveType />
      <div
        id="reaction-form"
        className="relative w-full flex-1 flex items-center overflow-x-auto gap-7 px-7 touch-auto"
      >
        {leftObjectiveVisible && leftObjectiveTemplate && (
          <ReactionForm
            className="flex items-center justify-center flex-1"
            template={leftObjectiveTemplate}
            slotValues={leftObjectiveSlots}
            isDisabled={activeTab !== LR.left || !leftObjectiveEnabled}
            onChange={(slotIndex, value) => {
              setObjectiveSlot(LR.left, slotIndex, value);
            }}
          />
        )}
        {leftObjectiveVisible &&
          rightObjectiveVisible &&
          yieldType !== YieldType.none && (
            <Icon
              id={yieldType === YieldType.both ? 'arrowDouble' : 'arrowRight'}
              className={classNames(
                'text-skyblue-light text-[45px] shrink-0',
                yieldType === YieldType.left && 'rotate-180'
              )}
            />
          )}
        {rightObjectiveVisible && rightObjectiveTemplate && (
          <ReactionForm
            className="flex items-center justify-center flex-1"
            template={rightObjectiveTemplate}
            slotValues={rightObjectiveSlots}
            isDisabled={activeTab !== LR.right || !rightObjectiveEnabled}
            onChange={(slotIndex, value) => {
              setObjectiveSlot(LR.right, slotIndex, value);
            }}
          />
        )}
      </div>
    </div>
  );
});
