import React from 'react';
import { useStores } from '../../providers/store/use-stores';
import { GamePiece } from '../GamePiece/GamePiece';
import { observer } from 'mobx-react-lite';
import { BOARD_PIECE_SIZE } from './consts';

export const BoardCursor = observer(() => {
  const {
    appStore: { electronDistributionStrategy },
    simulationStore: { cue, board, selectedGamePiece },
    inputStore: { cursorCoords },
  } = useStores();

  const boardpiece = cursorCoords
    ? board.getBoardPieceByCoords(cursorCoords)
    : null;

  const shouldShowCue = cue && cursorCoords && boardpiece && !boardpiece.piece;

  const shouldShowMouseCursor = Boolean(cursorCoords); //activeControlScheme !== ControlScheme.keyboard;

  return (
    <>
      {shouldShowCue && (
        <GamePiece
          data={cue}
          isCue
          style={{
            transform: `translate(${cursorCoords[0] * BOARD_PIECE_SIZE}px, ${
              cursorCoords[1] * BOARD_PIECE_SIZE
            }px)`,
          }}
          eds={electronDistributionStrategy}
        />
      )}
      {selectedGamePiece && !boardpiece?.piece && (
        <GamePiece
          style={{
            transform: `translate(${cursorCoords[0] * BOARD_PIECE_SIZE}px, ${
              cursorCoords[1] * BOARD_PIECE_SIZE
            }px)`,
          }}
          data={selectedGamePiece}
          isDragged
          eds={electronDistributionStrategy}
        />
      )}
      {shouldShowMouseCursor && cursorCoords && (
        <rect
          x={cursorCoords[0] * BOARD_PIECE_SIZE}
          y={cursorCoords[1] * BOARD_PIECE_SIZE}
          width={BOARD_PIECE_SIZE}
          height={BOARD_PIECE_SIZE}
          strokeWidth={2}
          stroke="deepskyblue"
          strokeDasharray="5 5"
          className="animatedDashOffset"
          fill="none"
          rx="6"
          ry="6"
        />
      )}
    </>
  );
});
